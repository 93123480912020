.cds--layout--size-xs {
  --cds-layout-size-height-context: var(--cds-layout-size-height-xs, 1.5rem);
  --cds-layout-size-height: var(--cds-layout-size-height-context);
}

.cds--layout-constraint--size__default-xs {
  --cds-layout-size-height: var(--cds-layout-size-height-context, var(--cds-layout-size-height-xs, 1.5rem));
}

.cds--layout-constraint--size__min-xs {
  --cds-layout-size-height-min: var(--cds-layout-size-height-xs, 1.5rem);
}

.cds--layout-constraint--size__max-xs {
  --cds-layout-size-height-max: var(--cds-layout-size-height-xs, 1.5rem);
}

.cds--layout--size-sm {
  --cds-layout-size-height-context: var(--cds-layout-size-height-sm, 2rem);
  --cds-layout-size-height: var(--cds-layout-size-height-context);
}

.cds--layout-constraint--size__default-sm {
  --cds-layout-size-height: var(--cds-layout-size-height-context, var(--cds-layout-size-height-sm, 2rem));
}

.cds--layout-constraint--size__min-sm {
  --cds-layout-size-height-min: var(--cds-layout-size-height-sm, 2rem);
}

.cds--layout-constraint--size__max-sm {
  --cds-layout-size-height-max: var(--cds-layout-size-height-sm, 2rem);
}

.cds--layout--size-md {
  --cds-layout-size-height-context: var(--cds-layout-size-height-md, 2.5rem);
  --cds-layout-size-height: var(--cds-layout-size-height-context);
}

.cds--layout-constraint--size__default-md {
  --cds-layout-size-height: var(--cds-layout-size-height-context, var(--cds-layout-size-height-md, 2.5rem));
}

.cds--layout-constraint--size__min-md {
  --cds-layout-size-height-min: var(--cds-layout-size-height-md, 2.5rem);
}

.cds--layout-constraint--size__max-md {
  --cds-layout-size-height-max: var(--cds-layout-size-height-md, 2.5rem);
}

.cds--layout--size-lg {
  --cds-layout-size-height-context: var(--cds-layout-size-height-lg, 3rem);
  --cds-layout-size-height: var(--cds-layout-size-height-context);
}

.cds--layout-constraint--size__default-lg {
  --cds-layout-size-height: var(--cds-layout-size-height-context, var(--cds-layout-size-height-lg, 3rem));
}

.cds--layout-constraint--size__min-lg {
  --cds-layout-size-height-min: var(--cds-layout-size-height-lg, 3rem);
}

.cds--layout-constraint--size__max-lg {
  --cds-layout-size-height-max: var(--cds-layout-size-height-lg, 3rem);
}

.cds--layout--size-xl {
  --cds-layout-size-height-context: var(--cds-layout-size-height-xl, 4rem);
  --cds-layout-size-height: var(--cds-layout-size-height-context);
}

.cds--layout-constraint--size__default-xl {
  --cds-layout-size-height: var(--cds-layout-size-height-context, var(--cds-layout-size-height-xl, 4rem));
}

.cds--layout-constraint--size__min-xl {
  --cds-layout-size-height-min: var(--cds-layout-size-height-xl, 4rem);
}

.cds--layout-constraint--size__max-xl {
  --cds-layout-size-height-max: var(--cds-layout-size-height-xl, 4rem);
}

.cds--layout--size-2xl {
  --cds-layout-size-height-context: var(--cds-layout-size-height-2xl, 5rem);
  --cds-layout-size-height: var(--cds-layout-size-height-context);
}

.cds--layout-constraint--size__default-2xl {
  --cds-layout-size-height: var(--cds-layout-size-height-context, var(--cds-layout-size-height-2xl, 5rem));
}

.cds--layout-constraint--size__min-2xl {
  --cds-layout-size-height-min: var(--cds-layout-size-height-2xl, 5rem);
}

.cds--layout-constraint--size__max-2xl {
  --cds-layout-size-height-max: var(--cds-layout-size-height-2xl, 5rem);
}

.cds--layout--density-condensed {
  --cds-layout-density-padding-inline-context: var(--cds-layout-density-padding-inline-condensed, 0.5rem);
  --cds-layout-density-padding-inline: var(--cds-layout-density-padding-inline-context);
}

.cds--layout-constraint--density__default-condensed {
  --cds-layout-density-padding-inline: var(--cds-layout-density-padding-inline-context, var(--cds-layout-density-padding-inline-condensed, 0.5rem));
}

.cds--layout-constraint--density__min-condensed {
  --cds-layout-density-padding-inline-min: var(--cds-layout-density-padding-inline-condensed, 0.5rem);
}

.cds--layout-constraint--density__max-condensed {
  --cds-layout-density-padding-inline-max: var(--cds-layout-density-padding-inline-condensed, 0.5rem);
}

.cds--layout--density-normal {
  --cds-layout-density-padding-inline-context: var(--cds-layout-density-padding-inline-normal, 1rem);
  --cds-layout-density-padding-inline: var(--cds-layout-density-padding-inline-context);
}

.cds--layout-constraint--density__default-normal {
  --cds-layout-density-padding-inline: var(--cds-layout-density-padding-inline-context, var(--cds-layout-density-padding-inline-normal, 1rem));
}

.cds--layout-constraint--density__min-normal {
  --cds-layout-density-padding-inline-min: var(--cds-layout-density-padding-inline-normal, 1rem);
}

.cds--layout-constraint--density__max-normal {
  --cds-layout-density-padding-inline-max: var(--cds-layout-density-padding-inline-normal, 1rem);
}

:root {
  --cds-layout-size-height-xs: 1.5rem;
  --cds-layout-size-height-sm: 2rem;
  --cds-layout-size-height-md: 2.5rem;
  --cds-layout-size-height-lg: 3rem;
  --cds-layout-size-height-xl: 4rem;
  --cds-layout-size-height-2xl: 5rem;
  --cds-layout-size-height-min: 0px;
  --cds-layout-size-height-max: 999999999px;
  --cds-layout-density-padding-inline-condensed: 0.5rem;
  --cds-layout-density-padding-inline-normal: 1rem;
  --cds-layout-density-padding-inline-min: 0px;
  --cds-layout-density-padding-inline-max: 999999999px;
}

:root {
  --cds-layer: var(--cds-layer-01, #f4f4f4);
  --cds-layer-active: var(--cds-layer-active-01, #c6c6c6);
  --cds-layer-hover: var(--cds-layer-hover-01, #e8e8e8);
  --cds-layer-selected: var(--cds-layer-selected-01, #e0e0e0);
  --cds-layer-selected-hover: var(--cds-layer-selected-hover-01, #d1d1d1);
  --cds-layer-accent: var(--cds-layer-accent-01, #e0e0e0);
  --cds-layer-accent-hover: var(--cds-layer-accent-hover-01, #d1d1d1);
  --cds-layer-accent-active: var(--cds-layer-accent-active-01, #a8a8a8);
  --cds-field: var(--cds-field-01, #f4f4f4);
  --cds-field-hover: var(--cds-field-hover-01, #e8e8e8);
  --cds-border-subtle: var(--cds-border-subtle-00, #e0e0e0);
  --cds-border-subtle-selected: var(--cds-border-subtle-selected-01, #c6c6c6);
  --cds-border-strong: var(--cds-border-strong-01, #8d8d8d);
  --cds-border-tile: var(--cds-border-tile-01, #c6c6c6);
}

.cds--layer-one {
  --cds-layer: var(--cds-layer-01, #f4f4f4);
  --cds-layer-active: var(--cds-layer-active-01, #c6c6c6);
  --cds-layer-hover: var(--cds-layer-hover-01, #e8e8e8);
  --cds-layer-selected: var(--cds-layer-selected-01, #e0e0e0);
  --cds-layer-selected-hover: var(--cds-layer-selected-hover-01, #d1d1d1);
  --cds-layer-accent: var(--cds-layer-accent-01, #e0e0e0);
  --cds-layer-accent-hover: var(--cds-layer-accent-hover-01, #d1d1d1);
  --cds-layer-accent-active: var(--cds-layer-accent-active-01, #a8a8a8);
  --cds-field: var(--cds-field-01, #f4f4f4);
  --cds-field-hover: var(--cds-field-hover-01, #e8e8e8);
  --cds-border-subtle: var(--cds-border-subtle-00, #e0e0e0);
  --cds-border-subtle-selected: var(--cds-border-subtle-selected-01, #c6c6c6);
  --cds-border-strong: var(--cds-border-strong-01, #8d8d8d);
  --cds-border-tile: var(--cds-border-tile-01, #c6c6c6);
}

.cds--layer-two {
  --cds-layer: var(--cds-layer-02, #ffffff);
  --cds-layer-active: var(--cds-layer-active-02, #c6c6c6);
  --cds-layer-hover: var(--cds-layer-hover-02, #e8e8e8);
  --cds-layer-selected: var(--cds-layer-selected-02, #e0e0e0);
  --cds-layer-selected-hover: var(--cds-layer-selected-hover-02, #d1d1d1);
  --cds-layer-accent: var(--cds-layer-accent-02, #e0e0e0);
  --cds-layer-accent-hover: var(--cds-layer-accent-hover-02, #d1d1d1);
  --cds-layer-accent-active: var(--cds-layer-accent-active-02, #a8a8a8);
  --cds-field: var(--cds-field-02, #ffffff);
  --cds-field-hover: var(--cds-field-hover-02, #e8e8e8);
  --cds-border-subtle: var(--cds-border-subtle-01, #c6c6c6);
  --cds-border-subtle-selected: var(--cds-border-subtle-selected-02, #c6c6c6);
  --cds-border-strong: var(--cds-border-strong-02, #8d8d8d);
  --cds-border-tile: var(--cds-border-tile-02, #a8a8a8);
}

.cds--layer-three {
  --cds-layer: var(--cds-layer-03, #f4f4f4);
  --cds-layer-active: var(--cds-layer-active-03, #c6c6c6);
  --cds-layer-hover: var(--cds-layer-hover-03, #e8e8e8);
  --cds-layer-selected: var(--cds-layer-selected-03, #e0e0e0);
  --cds-layer-selected-hover: var(--cds-layer-selected-hover-03, #d1d1d1);
  --cds-layer-accent: var(--cds-layer-accent-03, #e0e0e0);
  --cds-layer-accent-hover: var(--cds-layer-accent-hover-03, #d1d1d1);
  --cds-layer-accent-active: var(--cds-layer-accent-active-03, #a8a8a8);
  --cds-field: var(--cds-field-03, #f4f4f4);
  --cds-field-hover: var(--cds-field-hover-03, #e8e8e8);
  --cds-border-subtle: var(--cds-border-subtle-02, #e0e0e0);
  --cds-border-subtle-selected: var(--cds-border-subtle-selected-03, #c6c6c6);
  --cds-border-strong: var(--cds-border-strong-03, #8d8d8d);
  --cds-border-tile: var(--cds-border-tile-03, #c6c6c6);
}

@keyframes cds--hide-feedback {
  0% {
    opacity: 1;
    visibility: inherit;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
@keyframes cds--show-feedback {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: inherit;
  }
}
@keyframes cds--skeleton {
  0% {
    opacity: 0.3;
    transform: scaleX(0);
    transform-origin: left;
  }
  20% {
    opacity: 1;
    transform: scaleX(1);
    transform-origin: left;
  }
  28% {
    transform: scaleX(1);
    transform-origin: right;
  }
  51% {
    transform: scaleX(0);
    transform-origin: right;
  }
  58% {
    transform: scaleX(0);
    transform-origin: right;
  }
  82% {
    transform: scaleX(1);
    transform-origin: right;
  }
  83% {
    transform: scaleX(1);
    transform-origin: left;
  }
  96% {
    transform: scaleX(0);
    transform-origin: left;
  }
  100% {
    opacity: 0.3;
    transform: scaleX(0);
    transform-origin: left;
  }
}
.cds--assistive-text,
.cds--visually-hidden {
  position: absolute;
  overflow: hidden;
  padding: 0;
  border: 0;
  margin: -1px;
  block-size: 1px;
  clip: rect(0, 0, 0, 0);
  inline-size: 1px;
  visibility: inherit;
  white-space: nowrap;
}

.cds--popover-container {
  display: inline-block;
}

.cds--popover-container:not(.cds--popover--auto-align) {
  position: relative;
}

.cds--popover--high-contrast .cds--popover {
  --cds-popover-background-color: var(--cds-background-inverse, #393939);
  --cds-popover-text-color: var(--cds-text-inverse, #ffffff);
}

.cds--popover--drop-shadow .cds--popover > .cds--popover-content {
  filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.2));
}

.cds--popover--caret {
  --cds-popover-offset: 0.625rem;
}

.cds--popover {
  position: absolute;
  z-index: 6000;
  filter: var(--cds-popover-drop-shadow, none);
  inset: 0;
  pointer-events: none;
}

.cds--popover-content {
  --cds-layout-size-height-sm: 2rem;
  --cds-layout-size-height-md: 2.5rem;
  --cds-layout-size-height-lg: 3rem;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  position: absolute;
  z-index: 6000;
  display: none;
  border-radius: var(--cds-popover-border-radius, 2px);
  background-color: var(--cds-popover-background-color, var(--cds-layer));
  color: var(--cds-popover-text-color, var(--cds-text-primary, #161616));
  inline-size: max-content;
  max-inline-size: 23rem;
  pointer-events: auto;
}
.cds--layout--size-sm .cds--popover-content {
  --cds-layout-size-height: var(--cds-layout-size-height-sm);
}
.cds--layout--size-md .cds--popover-content {
  --cds-layout-size-height: var(--cds-layout-size-height-md);
}
.cds--layout--size-lg .cds--popover-content {
  --cds-layout-size-height: var(--cds-layout-size-height-lg);
}
.cds--popover-content *,
.cds--popover-content *::before,
.cds--popover-content *::after {
  box-sizing: inherit;
}

.cds--popover--open > .cds--popover > .cds--popover-content {
  display: block;
}

.cds--popover-content::before {
  position: absolute;
  display: none;
  content: "";
}

.cds--popover--open > .cds--popover > .cds--popover-content::before {
  display: block;
}

.cds--popover-caret,
.cds--popover--auto-align.cds--popover-caret {
  position: absolute;
  z-index: 6000;
  display: none;
  background-color: var(--cds-popover-background-color, var(--cds-layer));
  will-change: transform;
}

.cds--popover--caret.cds--popover--open > .cds--popover > .cds--popover-caret {
  display: block;
}

.cds--popover--auto-align.cds--popover--caret.cds--popover--open > .cds--popover > .cds--popover-content > .cds--popover-caret {
  display: block;
}

.cds--popover--tab-tip > .cds--popover > .cds--popover-caret {
  display: none;
}

.cds--popover--bottom:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-content {
  inset-block-end: 0;
  inset-inline-start: 50%;
  transform: translate(-50%, calc(100% + var(--cds-popover-offset, 0rem)));
}

[dir=rtl] .cds--popover--bottom:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-content {
  transform: translate(50%, calc(100% + var(--cds-popover-offset, 0rem)));
}

.cds--popover--bottom-left:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-content,
.cds--popover--bottom-start:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-content {
  inset-block-end: 0;
  inset-inline-start: 0;
  transform: translate(calc(-1 * var(--cds-popover-offset, 0rem)), calc(100% + var(--cds-popover-offset, 0rem)));
}

[dir=rtl] .cds--popover--bottom-left:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-content,
[dir=rtl] .cds--popover--bottom-start:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-content {
  inset-inline-end: 0;
  inset-inline-start: initial;
}

.cds--popover--bottom-right:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-content,
.cds--popover--bottom-end:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-content {
  inset-block-end: 0;
  inset-inline-end: 0;
  transform: translate(var(--cds-popover-offset, 0rem), calc(100% + var(--cds-popover-offset, 0rem)));
}

.cds--popover--bottom-end:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-caret,
.cds--popover--top-end:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-caret {
  inset-block-end: 0;
  inset-inline-end: 0;
  inset-inline-start: auto;
}

.cds--popover--bottom-start:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-caret {
  inset-block-end: 0;
  inset-inline-end: auto;
  inset-inline-start: 0;
  transform: translate(50%, calc(var(--cds-popover-offset, 0rem)));
}

.cds--popover--top-start:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-caret {
  inset-block-end: 0;
  inset-inline-end: auto;
  inset-inline-start: 0;
  transform: translate(50%, calc(-1 * var(--cds-popover-offset, 0rem)));
}

[dir=rtl] .cds--popover--bottom-right:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-content,
[dir=rtl] .cds--popover--bottom-end:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-content {
  inset-inline-start: 0;
}

.cds--popover--bottom > .cds--popover > .cds--popover-content::before,
.cds--popover--bottom-left > .cds--popover > .cds--popover-content::before,
.cds--popover--bottom-start > .cds--popover > .cds--popover-content::before,
.cds--popover--bottom-right > .cds--popover > .cds--popover-content::before,
.cds--popover--bottom-end > .cds--popover > .cds--popover-content::before {
  block-size: var(--cds-popover-offset, 0rem);
  inset-block-start: 0;
  inset-inline: 0;
  transform: translateY(-100%);
}

.cds--popover--bottom > .cds--popover > .cds--popover-caret,
.cds--popover--bottom-left > .cds--popover > .cds--popover-caret,
.cds--popover--bottom-start > .cds--popover > .cds--popover-caret,
.cds--popover--bottom-right > .cds--popover > .cds--popover-caret,
.cds--popover--bottom-end > .cds--popover > .cds--popover-caret {
  block-size: var(--cds-popover-caret-height, 0.375rem);
  clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
  inline-size: var(--cds-popover-caret-width, 0.75rem);
  inset-block-end: 0;
  inset-inline-start: 50%;
  transform: translate(-50%, var(--cds-popover-offset, 0rem));
}

[dir=rtl] .cds--popover--bottom > .cds--popover > .cds--popover-caret,
[dir=rtl] .cds--popover--bottom-left > .cds--popover > .cds--popover-caret,
[dir=rtl] .cds--popover--bottom-start > .cds--popover > .cds--popover-caret,
[dir=rtl] .cds--popover--bottom-right > .cds--popover > .cds--popover-caret,
[dir=rtl] .cds--popover--bottom-end > .cds--popover > .cds--popover-caret {
  transform: translate(50%, var(--cds-popover-offset, 0rem));
}

.cds--popover--bottom.cds--popover--auto-align > .cds--popover > .cds--popover-content > .cds--popover-caret,
.cds--popover--bottom-left.cds--popover--auto-align > .cds--popover > .cds--popover-content > .cds--popover-caret,
.cds--popover--bottom-start.cds--popover--auto-align > .cds--popover > .cds--popover-content > .cds--popover-caret,
.cds--popover--bottom-right.cds--popover--auto-align > .cds--popover > .cds--popover-content > .cds--popover-caret,
.cds--popover--bottom-end.cds--popover--auto-align > .cds--popover > .cds--popover-content > .cds--popover-caret {
  block-size: var(--cds-popover-caret-height, 0.375rem);
  clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
  inline-size: var(--cds-popover-caret-width, 0.75rem);
}

.cds--popover--top:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-content {
  inset-block-start: 0;
  inset-inline-start: 50%;
  transform: translate(-50%, calc(-100% - var(--cds-popover-offset, 0rem)));
}

[dir=rtl] .cds--popover--top:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-content {
  transform: translate(50%, calc(-100% - var(--cds-popover-offset, 0rem)));
}

.cds--popover--top-left:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-content,
.cds--popover--top-start:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-content {
  inset-block-start: 0;
  inset-inline-start: 0;
  transform: translate(calc(-1 * var(--cds-popover-offset, 0rem)), calc(-100% - var(--cds-popover-offset, 0rem)));
}

[dir=rtl] .cds--popover--top-left:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-content,
[dir=rtl] .cds--popover--top-start:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-content {
  inset-inline-end: 0;
  inset-inline-start: initial;
}

.cds--popover--top-right:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-content,
.cds--popover--top-end:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-content {
  inset-block-start: 0;
  inset-inline-end: 0;
  transform: translate(var(--cds-popover-offset, 0rem), calc(-100% - var(--cds-popover-offset, 0rem)));
}

[dir=rtl] .cds--popover--top-right:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-content,
[dir=rtl] .cds--popover--top-end:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-content {
  inset-inline-start: 0;
}

.cds--popover--top > .cds--popover > .cds--popover-content::before,
.cds--popover--top-left > .cds--popover > .cds--popover-content::before,
.cds--popover--top-start > .cds--popover > .cds--popover-content::before,
.cds--popover--top-right > .cds--popover > .cds--popover-content::before,
.cds--popover--top-end > .cds--popover > .cds--popover-content::before {
  block-size: var(--cds-popover-offset, 0rem);
  inset-block-end: 0;
  inset-inline: 0;
  transform: translateY(100%);
}

.cds--popover--top > .cds--popover > .cds--popover-caret,
.cds--popover--top-left > .cds--popover > .cds--popover-caret,
.cds--popover--top-start > .cds--popover > .cds--popover-caret,
.cds--popover--top-right > .cds--popover > .cds--popover-caret,
.cds--popover--top-end > .cds--popover > .cds--popover-caret {
  block-size: var(--cds-popover-caret-height, 0.375rem);
  clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
  inline-size: var(--cds-popover-caret-width, 0.75rem);
  inset-block-start: 0;
  inset-inline-start: 50%;
  transform: translate(-50%, calc(-1 * var(--cds-popover-offset, 0rem)));
}

[dir=rtl] .cds--popover--top:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-caret,
[dir=rtl] .cds--popover--top-left:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-caret,
[dir=rtl] .cds--popover--top-start:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-caret,
[dir=rtl] .cds--popover--top-right:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-caret,
[dir=rtl] .cds--popover--top-end:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-caret {
  transform: translate(50%, calc(-1 * var(--cds-popover-offset, 0rem)));
}

.cds--popover--top.cds--popover--auto-align > .cds--popover > .cds--popover-content > .cds--popover-caret,
.cds--popover--top-left.cds--popover--auto-align > .cds--popover > .cds--popover-content > .cds--popover-caret,
.cds--popover--top-start.cds--popover--auto-align > .cds--popover > .cds--popover-content > .cds--popover-caret,
.cds--popover--top-right.cds--popover--auto-align > .cds--popover > .cds--popover-content > .cds--popover-caret,
.cds--popover--top-end.cds--popover--auto-align > .cds--popover > .cds--popover-content > .cds--popover-caret {
  block-size: var(--cds-popover-caret-height, 0.375rem);
  clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
  inline-size: var(--cds-popover-caret-width, 0.75rem);
}

.cds--popover--right:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-content {
  inset-block-start: 50%;
  inset-inline-start: 100%;
  transform: translate(var(--cds-popover-offset, 0rem), -50%);
}

.cds--popover--right-top:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-content,
.cds--popover--right-start:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-content {
  inset-block-start: 50%;
  inset-inline-start: 100%;
  transform: translate(var(--cds-popover-offset, 0rem), calc(0.5 * var(--cds-popover-offset, 0rem) * -1 - 16px));
}

.cds--popover--right-bottom:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-content,
.cds--popover--right-end:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-content {
  inset-block-end: 50%;
  inset-inline-start: 100%;
  transform: translate(var(--cds-popover-offset, 0rem), calc(0.5 * var(--cds-popover-offset, 0rem) + 16px));
}

[dir=rtl] .cds--popover--right:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-content,
[dir=rtl] .cds--popover--right-bottom:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-content,
[dir=rtl] .cds--popover--right-end:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-content,
[dir=rtl] .cds--popover--right-top:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-content,
[dir=rtl] .cds--popover--right-start:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-content {
  inset-inline-end: 100%;
  inset-inline-start: initial;
}

.cds--popover--right > .cds--popover > .cds--popover-content::before,
.cds--popover--right-top > .cds--popover > .cds--popover-content::before,
.cds--popover--right-start > .cds--popover > .cds--popover-content::before,
.cds--popover--right-bottom > .cds--popover > .cds--popover-content::before,
.cds--popover--right-end > .cds--popover > .cds--popover-content::before {
  inline-size: var(--cds-popover-offset, 0rem);
  inset-block: 0;
  inset-inline-start: 0;
  transform: translateX(-100%);
}

.cds--popover--right:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-caret,
.cds--popover--right-top:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-caret,
.cds--popover--right-start:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-caret,
.cds--popover--right-bottom:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-caret,
.cds--popover--right-end:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-caret {
  block-size: var(--cds-popover-caret-width, 0.75rem);
  clip-path: polygon(0% 50%, 100% 0%, 100% 100%);
  inline-size: var(--cds-popover-caret-height, 0.375rem);
  inset-block-start: 50%;
  inset-inline-start: 100%;
  transform: translate(calc(var(--cds-popover-offset, 0rem) - 100%), -50%);
}

[dir=rtl] .cds--popover--right:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-caret,
[dir=rtl] .cds--popover--right-top:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-caret,
[dir=rtl] .cds--popover--right-start:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-caret,
[dir=rtl] .cds--popover--right-bottom:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-caret,
[dir=rtl] .cds--popover--right-end:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-caret {
  inset-inline-end: 100%;
  inset-inline-start: initial;
}

.cds--popover--right.cds--popover--auto-align > .cds--popover > .cds--popover-content > .cds--popover-caret,
.cds--popover--right-top.cds--popover--auto-align > .cds--popover > .cds--popover-content > .cds--popover-caret,
.cds--popover--right-start.cds--popover--auto-align > .cds--popover > .cds--popover-content > .cds--popover-caret,
.cds--popover--right-bottom.cds--popover--auto-align > .cds--popover > .cds--popover-content > .cds--popover-caret,
.cds--popover--right-end.cds--popover--auto-align > .cds--popover > .cds--popover-content > .cds--popover-caret {
  block-size: var(--cds-popover-caret-width, 0.75rem);
  clip-path: polygon(0% 50%, 100% 0%, 100% 100%);
  inline-size: var(--cds-popover-caret-height, 0.375rem);
}

.cds--popover--left:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-content {
  inset-block-start: 50%;
  inset-inline-end: 100%;
  transform: translate(calc(-1 * var(--cds-popover-offset, 0rem) + 0.1px), -50%);
}

.cds--popover--left-top:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-content,
.cds--popover--left-start:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-content {
  inset-block-start: 50%;
  inset-inline-end: 100%;
  transform: translate(calc(-1 * var(--cds-popover-offset, 0rem)), calc(-0.5 * var(--cds-popover-offset, 0rem) - 16px));
}

.cds--popover--left-bottom:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-content,
.cds--popover--left-end:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-content {
  inset-block-end: 50%;
  inset-inline-end: 100%;
  transform: translate(calc(-1 * var(--cds-popover-offset, 0rem)), calc(0.5 * var(--cds-popover-offset, 0rem) + 16px));
}

[dir=rtl] .cds--popover--left:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-content,
[dir=rtl] .cds--popover--left-bottom:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-content,
[dir=rtl] .cds--popover--left-end:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-content,
[dir=rtl] .cds--popover--left-top:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-content,
[dir=rtl] .cds--popover--left-start:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-content {
  inset-inline-end: initial;
  inset-inline-start: 100%;
}

.cds--popover--left > .cds--popover > .cds--popover-content::before,
.cds--popover--left-top > .cds--popover > .cds--popover-content::before,
.cds--popover--left-start > .cds--popover > .cds--popover-content::before,
.cds--popover--left-bottom > .cds--popover > .cds--popover-content::before,
.cds--popover--left-end > .cds--popover > .cds--popover-content::before {
  inline-size: var(--cds-popover-offset, 0rem);
  inset-block: 0;
  inset-inline-end: 0;
  transform: translateX(100%);
}

.cds--popover--left:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-caret,
.cds--popover--left-top:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-caret,
.cds--popover--left-start:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-caret,
.cds--popover--left-bottom:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-caret,
.cds--popover--left-end:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-caret {
  block-size: var(--cds-popover-caret-width, 0.75rem);
  clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
  inline-size: var(--cds-popover-caret-height, 0.375rem);
  inset-block-start: 50%;
  inset-inline-end: 100%;
  transform: translate(calc(-1 * var(--cds-popover-offset, 0rem) + 100%), -50%);
}

[dir=rtl] .cds--popover--left:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-caret,
[dir=rtl] .cds--popover--left-top:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-caret,
[dir=rtl] .cds--popover--left-start:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-caret,
[dir=rtl] .cds--popover--left-bottom:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-caret,
[dir=rtl] .cds--popover--left-end:not(.cds--popover--auto-align) > .cds--popover > .cds--popover-caret {
  inset-inline-end: initial;
  inset-inline-start: 100%;
}

.cds--popover--left.cds--popover--auto-align > .cds--popover > .cds--popover-content > .cds--popover-caret,
.cds--popover--left-top.cds--popover--auto-align > .cds--popover > .cds--popover-content > .cds--popover-caret,
.cds--popover--left-start.cds--popover--auto-align > .cds--popover > .cds--popover-content > .cds--popover-caret,
.cds--popover--left-bottom.cds--popover--auto-align > .cds--popover > .cds--popover-content > .cds--popover-caret,
.cds--popover--left-end.cds--popover--auto-align > .cds--popover > .cds--popover-content > .cds--popover-caret {
  block-size: var(--cds-popover-caret-width, 0.75rem);
  clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
  inline-size: var(--cds-popover-caret-height, 0.375rem);
}

.cds--popover--tab-tip > .cds--popover > .cds--popover-content {
  border-radius: 0;
}

.cds--popover--tab-tip .cds--popover {
  will-change: filter;
}

.cds--popover--tab-tip__button {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  appearance: none;
  background: none;
  cursor: pointer;
  text-align: start;
  inline-size: 100%;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  block-size: 2rem;
  inline-size: 2rem;
}
.cds--popover--tab-tip__button *,
.cds--popover--tab-tip__button *::before,
.cds--popover--tab-tip__button *::after {
  box-sizing: inherit;
}
.cds--popover--tab-tip__button::-moz-focus-inner {
  border: 0;
}
.cds--popover--tab-tip__button:focus {
  outline: 2px solid var(--cds-focus, #0f62fe);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--popover--tab-tip__button:focus {
    outline-style: dotted;
  }
}
.cds--popover--tab-tip__button:hover {
  background-color: var(--cds-layer-hover);
}

.cds--popover--tab-tip.cds--popover--open .cds--popover--tab-tip__button {
  background: var(--cds-layer);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
}

.cds--popover--tab-tip.cds--popover--open .cds--popover--tab-tip__button:not(:focus)::after {
  position: absolute;
  z-index: 6001;
  background: var(--cds-layer);
  block-size: 2px;
  content: "";
  inline-size: 100%;
  inset-block-end: 0;
}

.cds--popover--tab-tip__button svg {
  fill: var(--cds-icon-primary, #161616);
}

.cds--tooltip {
  --cds-popover-offset: 12px;
}

.cds--tooltip-content {
  font-size: var(--cds-body-01-font-size, 0.875rem);
  font-weight: var(--cds-body-01-font-weight, 400);
  line-height: var(--cds-body-01-line-height, 1.42857);
  letter-spacing: var(--cds-body-01-letter-spacing, 0.16px);
  padding: var(--cds-tooltip-padding-block, 1rem) var(--cds-tooltip-padding-inline, 1rem);
  max-inline-size: 18rem;
}

.cds--icon-tooltip {
  --cds-tooltip-padding-block: 0.125rem;
  --cds-popover-caret-width: 0.5rem;
  --cds-popover-caret-height: 0.25rem;
  --cds-popover-offset: 0.5rem;
}

.cds--icon-tooltip .cds--tooltip-content {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
}

.cds--definition-term {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  appearance: none;
  background: none;
  cursor: pointer;
  text-align: start;
  inline-size: 100%;
  border-radius: 0;
  border-block-end: 1px dotted var(--cds-border-strong);
  color: var(--cds-text-primary, #161616);
}
.cds--definition-term *,
.cds--definition-term *::before,
.cds--definition-term *::after {
  box-sizing: inherit;
}
.cds--definition-term::-moz-focus-inner {
  border: 0;
}

.cds--definition-term:focus {
  outline: 1px solid var(--cds-focus, #0f62fe);
  border-block-end-color: var(--cds-border-interactive, #0f62fe);
}
@media screen and (prefers-contrast) {
  .cds--definition-term:focus {
    outline-style: dotted;
  }
}

.cds--definition-term:hover {
  border-block-end-color: var(--cds-border-interactive, #0f62fe);
}

.cds--definition-tooltip {
  font-size: var(--cds-body-01-font-size, 0.875rem);
  font-weight: var(--cds-body-01-font-weight, 400);
  line-height: var(--cds-body-01-line-height, 1.42857);
  letter-spacing: var(--cds-body-01-letter-spacing, 0.16px);
  padding: 0.5rem 1rem;
  max-inline-size: 11rem;
  text-wrap: auto;
  word-break: break-word;
}

.cds--btn {
  --cds-layout-size-height-local: clamp(max(var(--cds-layout-size-height-min), var(--cds-layout-size-height-sm)), var(--cds-layout-size-height, var(--cds-layout-size-height-lg)), min(var(--cds-layout-size-height-max), var(--cds-layout-size-height-2xl)));
  --cds-layout-density-padding-inline-local: clamp(var(--cds-layout-density-padding-inline-min), var(--cds-layout-density-padding-inline, var(--cds-layout-density-padding-inline-normal)), var(--cds-layout-density-padding-inline-max));
  --temp-1lh: (
    var(--cds-body-compact-01-line-height, 1.28572) * 1em
  );
  --temp-expressive-1lh: (
    var(--cds-body-compact-02-line-height, 1.375) * 1em
  );
  --temp-padding-block-max: calc(
    (var(--cds-layout-size-height-lg) - var(--temp-1lh)) / 2 -
      0.0625rem
  );
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  position: relative;
  display: inline-flex;
  flex-shrink: 0;
  justify-content: space-between;
  border-radius: 0;
  margin: 0;
  cursor: pointer;
  inline-size: max-content;
  max-inline-size: 20rem;
  min-block-size: var(--cds-layout-size-height-local);
  outline: none;
  padding-block: min((var(--cds-layout-size-height-local) - var(--temp-1lh)) / 2 - 0.0625rem, var(--temp-padding-block-max));
  padding-inline: calc(var(--cds-layout-density-padding-inline-local) - 0.0625rem) calc(var(--cds-layout-density-padding-inline-local) * 3 + 1rem - 0.0625rem);
  text-align: start;
  text-decoration: none;
  transition: background 70ms cubic-bezier(0, 0, 0.38, 0.9), box-shadow 70ms cubic-bezier(0, 0, 0.38, 0.9), border-color 70ms cubic-bezier(0, 0, 0.38, 0.9), outline 70ms cubic-bezier(0, 0, 0.38, 0.9);
  vertical-align: top;
}
.cds--btn *,
.cds--btn *::before,
.cds--btn *::after {
  box-sizing: inherit;
}
.cds--btn:disabled, .cds--btn:hover:disabled, .cds--btn:focus:disabled, .cds--btn.cds--btn--disabled, .cds--btn.cds--btn--disabled:hover, .cds--btn.cds--btn--disabled:focus {
  border-color: var(--cds-button-disabled, #c6c6c6);
  background: var(--cds-button-disabled, #c6c6c6);
  box-shadow: none;
  color: var(--cds-text-on-color-disabled, #8d8d8d);
  cursor: not-allowed;
}
.cds--btn .cds--btn__icon {
  position: absolute;
  flex-shrink: 0;
  block-size: 1rem;
  inline-size: 1rem;
  inset-block-start: min((var(--cds-layout-size-height-local) - 1rem) / 2 - 0.0625rem, var(--temp-padding-block-max));
  inset-inline-end: var(--cds-layout-density-padding-inline-local);
  margin-block-start: 0.0625rem;
}

.cds--btn::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.cds--btn--primary {
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  background-color: var(--cds-button-primary, #0f62fe);
  color: var(--cds-text-on-color, #ffffff);
}
.cds--btn--primary:hover {
  background-color: var(--cds-button-primary-hover, #0050e6);
}
.cds--btn--primary:focus {
  border-color: var(--cds-button-focus-color, var(--cds-focus, #0f62fe));
  box-shadow: inset 0 0 0 1px var(--cds-button-focus-color, var(--cds-focus, #0f62fe)), inset 0 0 0 2px var(--cds-background, #ffffff);
}
.cds--btn--primary:active {
  background-color: var(--cds-button-primary-active, #002d9c);
}
.cds--btn--primary .cds--btn__icon,
.cds--btn--primary .cds--btn__icon path:not([data-icon-path]):not([fill=none]) {
  fill: currentColor;
}
.cds--btn--primary:hover {
  color: var(--cds-text-on-color, #ffffff);
}

.cds--btn--secondary {
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  background-color: var(--cds-button-secondary, #393939);
  color: var(--cds-text-on-color, #ffffff);
}
.cds--btn--secondary:hover {
  background-color: var(--cds-button-secondary-hover, #474747);
}
.cds--btn--secondary:focus {
  border-color: var(--cds-button-focus-color, var(--cds-focus, #0f62fe));
  box-shadow: inset 0 0 0 1px var(--cds-button-focus-color, var(--cds-focus, #0f62fe)), inset 0 0 0 2px var(--cds-background, #ffffff);
}
.cds--btn--secondary:active {
  background-color: var(--cds-button-secondary-active, #6f6f6f);
}
.cds--btn--secondary .cds--btn__icon,
.cds--btn--secondary .cds--btn__icon path:not([data-icon-path]):not([fill=none]) {
  fill: currentColor;
}
.cds--btn--secondary:hover, .cds--btn--secondary:focus {
  color: var(--cds-text-on-color, #ffffff);
}

.cds--btn--tertiary {
  border-width: 1px;
  border-style: solid;
  border-color: var(--cds-button-tertiary, #0f62fe);
  background-color: transparent;
  color: var(--cds-button-tertiary, #0f62fe);
}
.cds--btn--tertiary:hover {
  background-color: var(--cds-button-tertiary-hover, #0050e6);
}
.cds--btn--tertiary:focus {
  border-color: var(--cds-button-focus-color, var(--cds-focus, #0f62fe));
  box-shadow: inset 0 0 0 1px var(--cds-button-focus-color, var(--cds-focus, #0f62fe)), inset 0 0 0 2px var(--cds-background, #ffffff);
}
.cds--btn--tertiary:active {
  background-color: var(--cds-button-tertiary-active, #002d9c);
}
.cds--btn--tertiary .cds--btn__icon,
.cds--btn--tertiary .cds--btn__icon path:not([data-icon-path]):not([fill=none]) {
  fill: currentColor;
}
.cds--btn--tertiary:hover {
  color: var(--cds-text-inverse, #ffffff);
}
.cds--btn--tertiary:focus {
  background-color: var(--cds-button-tertiary, #0f62fe);
  color: var(--cds-text-inverse, #ffffff);
}
.cds--btn--tertiary:active {
  border-color: transparent;
  background-color: var(--cds-button-tertiary-active, #002d9c);
  color: var(--cds-text-inverse, #ffffff);
}
.cds--btn--tertiary:disabled, .cds--btn--tertiary:hover:disabled, .cds--btn--tertiary:focus:disabled, .cds--btn--tertiary.cds--btn--disabled, .cds--btn--tertiary.cds--btn--disabled:hover, .cds--btn--tertiary.cds--btn--disabled:focus {
  background: transparent;
  color: var(--cds-text-on-color-disabled, #8d8d8d);
  outline: none;
}

.cds--btn--ghost {
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  background-color: transparent;
  color: var(--cds-link-primary, #0f62fe);
  padding-inline-end: calc(var(--cds-layout-density-padding-inline-local) - 0.0625rem);
}
.cds--btn--ghost:hover {
  background-color: var(--cds-background-hover, rgba(141, 141, 141, 0.12));
}
.cds--btn--ghost:focus {
  border-color: var(--cds-button-focus-color, var(--cds-focus, #0f62fe));
  box-shadow: inset 0 0 0 1px var(--cds-button-focus-color, var(--cds-focus, #0f62fe)), inset 0 0 0 2px var(--cds-background, #ffffff);
}
.cds--btn--ghost:active {
  background-color: var(--cds-background-active, rgba(141, 141, 141, 0.5));
}
.cds--btn--ghost .cds--btn__icon,
.cds--btn--ghost .cds--btn__icon path:not([data-icon-path]):not([fill=none]) {
  fill: currentColor;
}
.cds--btn--ghost .cds--btn__icon {
  position: static;
  align-self: center;
  margin-inline-start: 0.5rem;
}
.cds--btn--ghost:hover, .cds--btn--ghost:active {
  color: var(--cds-link-primary-hover, #0043ce);
}
.cds--btn--ghost:active {
  background-color: var(--cds-background-active, rgba(141, 141, 141, 0.5));
}
.cds--btn--ghost:disabled, .cds--btn--ghost:hover:disabled, .cds--btn--ghost:focus:disabled, .cds--btn--ghost.cds--btn--disabled, .cds--btn--ghost.cds--btn--disabled:hover, .cds--btn--ghost.cds--btn--disabled:focus {
  border-color: transparent;
  background: transparent;
  color: var(--cds-text-on-color-disabled, #8d8d8d);
  outline: none;
}
.cds--btn--ghost:not([disabled]) svg {
  fill: var(--cds-icon-primary, #161616);
}

.cds--btn--icon-only {
  justify-content: center;
  padding: 0;
  block-size: var(--cds-layout-size-height-local);
  inline-size: var(--cds-layout-size-height-local);
  padding-block-start: min((var(--cds-layout-size-height-local) - 1rem) / 2 - 0.0625rem, var(--temp-padding-block-max));
}
.cds--btn--icon-only > :first-child {
  min-inline-size: 1rem;
}
.cds--btn--icon-only .cds--btn__icon {
  position: static;
}
.cds--btn--icon-only.cds--btn--ghost .cds--btn__icon, .cds--btn--icon-only.cds--btn--danger--ghost .cds--btn__icon {
  margin: 0;
}
.cds--btn--icon-only.cds--btn--danger--ghost {
  padding-inline-end: calc(var(--cds-layout-density-padding-inline-local) - 1rem);
}

.cds--btn--sm:not(.cds--btn--icon-only) .cds--btn__icon,
.cds--btn--md:not(.cds--btn--icon-only) .cds--btn__icon {
  margin-block-start: 0;
}

.cds--btn--icon-only.cds--btn--selected {
  background: var(--cds-background-selected, rgba(141, 141, 141, 0.2));
}

.cds--btn path[data-icon-path=inner-path] {
  fill: none;
}

.cds--btn--ghost.cds--btn--icon-only .cds--btn__icon path:not([data-icon-path]):not([fill=none]),
.cds--btn--ghost.cds--btn--icon-only .cds--btn__icon {
  fill: var(--cds-icon-primary, #161616);
}

.cds--btn--ghost.cds--btn--icon-only[disabled] .cds--btn__icon path:not([data-icon-path]):not([fill=none]),
.cds--btn--ghost.cds--btn--icon-only[disabled] .cds--btn__icon,
.cds--btn.cds--btn--icon-only.cds--btn--ghost[disabled]:hover .cds--btn__icon {
  fill: var(--cds-icon-on-color-disabled, #8d8d8d);
}

.cds--btn--ghost.cds--btn--icon-only[disabled] {
  cursor: not-allowed;
}

.cds--icon-tooltip--disabled .cds--tooltip-trigger__wrapper {
  cursor: not-allowed;
}

.cds--icon-tooltip--disabled .cds--btn--icon-only[disabled] {
  pointer-events: none;
}

.cds--btn--danger {
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  background-color: var(--cds-button-danger-primary, #da1e28);
  color: var(--cds-text-on-color, #ffffff);
}
.cds--btn--danger:hover {
  background-color: var(--cds-button-danger-hover, #b81921);
}
.cds--btn--danger:focus {
  border-color: var(--cds-button-focus-color, var(--cds-focus, #0f62fe));
  box-shadow: inset 0 0 0 1px var(--cds-button-focus-color, var(--cds-focus, #0f62fe)), inset 0 0 0 2px var(--cds-background, #ffffff);
}
.cds--btn--danger:active {
  background-color: var(--cds-button-danger-active, #750e13);
}
.cds--btn--danger .cds--btn__icon,
.cds--btn--danger .cds--btn__icon path:not([data-icon-path]):not([fill=none]) {
  fill: currentColor;
}
.cds--btn--danger:hover {
  color: var(--cds-text-on-color, #ffffff);
}
.cds--btn--danger--tertiary {
  border-width: 1px;
  border-style: solid;
  border-color: var(--cds-button-danger-secondary, #da1e28);
  background-color: transparent;
  color: var(--cds-button-danger-secondary, #da1e28);
}
.cds--btn--danger--tertiary:hover {
  background-color: var(--cds-button-danger-hover, #b81921);
}
.cds--btn--danger--tertiary:focus {
  border-color: var(--cds-button-focus-color, var(--cds-focus, #0f62fe));
  box-shadow: inset 0 0 0 1px var(--cds-button-focus-color, var(--cds-focus, #0f62fe)), inset 0 0 0 2px var(--cds-background, #ffffff);
}
.cds--btn--danger--tertiary:active {
  background-color: var(--cds-button-danger-active, #750e13);
}
.cds--btn--danger--tertiary .cds--btn__icon,
.cds--btn--danger--tertiary .cds--btn__icon path:not([data-icon-path]):not([fill=none]) {
  fill: currentColor;
}
.cds--btn--danger--tertiary:hover {
  border-color: var(--cds-button-danger-hover, #b81921);
  color: var(--cds-text-on-color, #ffffff);
}
.cds--btn--danger--tertiary:focus {
  background-color: var(--cds-button-danger-primary, #da1e28);
  color: var(--cds-text-on-color, #ffffff);
}
.cds--btn--danger--tertiary:active {
  border-color: var(--cds-button-danger-active, #750e13);
  background-color: var(--cds-button-danger-active, #750e13);
  color: var(--cds-text-on-color, #ffffff);
}
.cds--btn--danger--tertiary:disabled, .cds--btn--danger--tertiary:hover:disabled, .cds--btn--danger--tertiary:focus:disabled, .cds--btn--danger--tertiary.cds--btn--disabled, .cds--btn--danger--tertiary.cds--btn--disabled:hover, .cds--btn--danger--tertiary.cds--btn--disabled:focus {
  background: transparent;
  color: var(--cds-text-on-color-disabled, #8d8d8d);
  outline: none;
}
.cds--btn--danger--ghost {
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  background-color: transparent;
  color: var(--cds-button-danger-secondary, #da1e28);
  padding-inline-end: calc(var(--cds-layout-density-padding-inline-local) - 0.0625rem);
}
.cds--btn--danger--ghost:hover {
  background-color: var(--cds-button-danger-hover, #b81921);
}
.cds--btn--danger--ghost:focus {
  border-color: var(--cds-button-focus-color, var(--cds-focus, #0f62fe));
  box-shadow: inset 0 0 0 1px var(--cds-button-focus-color, var(--cds-focus, #0f62fe)), inset 0 0 0 2px var(--cds-background, #ffffff);
}
.cds--btn--danger--ghost:active {
  background-color: var(--cds-button-danger-active, #750e13);
}
.cds--btn--danger--ghost .cds--btn__icon,
.cds--btn--danger--ghost .cds--btn__icon path:not([data-icon-path]):not([fill=none]) {
  fill: currentColor;
}
.cds--btn--danger--ghost .cds--btn__icon {
  position: static;
  margin-inline-start: 0.5rem;
}
.cds--btn--danger--ghost:hover, .cds--btn--danger--ghost:active {
  color: var(--cds-text-on-color, #ffffff);
}
.cds--btn--danger--ghost:disabled, .cds--btn--danger--ghost:hover:disabled, .cds--btn--danger--ghost:focus:disabled, .cds--btn--danger--ghost.cds--btn--disabled, .cds--btn--danger--ghost.cds--btn--disabled:hover, .cds--btn--danger--ghost.cds--btn--disabled:focus {
  border-color: transparent;
  background: transparent;
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  outline: none;
}

.cds--btn--expressive {
  font-size: var(--cds-body-compact-02-font-size, 1rem);
  font-weight: var(--cds-body-compact-02-font-weight, 400);
  line-height: var(--cds-body-compact-02-line-height, 1.375);
  letter-spacing: var(--cds-body-compact-02-letter-spacing, 0);
  padding-block: min((var(--cds-layout-size-height-local) - var(--temp-expressive-1lh)) / 2 - 0.0625rem, var(--temp-padding-block-max));
}

.cds--btn--icon-only.cds--btn--expressive {
  padding: 12px 13px;
}

.cds--btn.cds--btn--expressive .cds--btn__icon {
  block-size: 1.25rem;
  inline-size: 1.25rem;
}

.cds--btn-set .cds--btn.cds--btn--expressive {
  max-inline-size: 20rem;
}

.cds--btn.cds--skeleton {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-background, #e8e8e8);
  box-shadow: none;
  pointer-events: none;
  inline-size: 9.375rem;
}
.cds--btn.cds--skeleton:hover, .cds--btn.cds--skeleton:focus, .cds--btn.cds--skeleton:active {
  border: none;
  cursor: default;
  outline: none;
}
.cds--btn.cds--skeleton::before {
  position: absolute;
  animation: 3000ms ease-in-out cds--skeleton infinite;
  background: var(--cds-skeleton-element, #c6c6c6);
  block-size: 100%;
  content: "";
  inline-size: 100%;
  inset-inline-start: 0;
  will-change: transform-origin, transform, opacity;
}
@media (prefers-reduced-motion: reduce) {
  .cds--btn.cds--skeleton::before {
    animation: none;
  }
}

.cds--btn-set {
  display: flex;
}

.cds--btn-set--stacked {
  flex-direction: column;
}

.cds--btn-set .cds--btn {
  inline-size: 100%;
  max-inline-size: 12.25rem;
}
.cds--btn-set .cds--btn:not(:focus) {
  box-shadow: -0.0625rem 0 0 0 var(--cds-button-separator, #e0e0e0);
}
.cds--btn-set .cds--btn:first-of-type:not(:focus) {
  box-shadow: inherit;
}

.cds--btn-set .cds--btn:focus + .cds--btn {
  box-shadow: inherit;
}

.cds--btn-set--stacked .cds--btn:not(:focus) {
  box-shadow: 0 -0.0625rem 0 0 var(--cds-button-separator, #e0e0e0);
}

.cds--btn-set--stacked .cds--btn:first-of-type:not(:focus) {
  box-shadow: inherit;
}

.cds--btn-set .cds--btn.cds--btn--disabled {
  box-shadow: -0.0625rem 0 0 0 var(--cds-icon-on-color-disabled, #8d8d8d);
}
.cds--btn-set .cds--btn.cds--btn--disabled:first-of-type {
  box-shadow: none;
}

.cds--btn-set--stacked .cds--btn.cds--btn--disabled {
  box-shadow: 0 -0.0625rem 0 0 var(--cds-layer-selected-disabled, #8d8d8d);
}
.cds--btn-set--stacked .cds--btn.cds--btn--disabled:first-of-type {
  box-shadow: none;
}

.cds--btn-set .cds--btn.cds--btn--loading {
  border-color: transparent;
  background-color: transparent;
  box-shadow: none;
}

.cds--btn--sm .cds--badge-indicator {
  margin-block-start: 0.25rem;
  margin-inline-end: 0.25rem;
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--btn:focus {
    color: Highlight;
    outline: 1px solid Highlight;
  }
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--btn--ghost.cds--btn--icon-only .cds--btn__icon path:not([data-icon-path]):not([fill=none]),
  .cds--btn--ghost.cds--btn--icon-only .cds--btn__icon {
    fill: ButtonText;
  }
}

[dir=rtl] .cds--btn-set .cds--btn:not(:focus) {
  box-shadow: 0.0625rem 0 0 0 var(--cds-button-separator, #e0e0e0);
}

.cds--content {
  padding: 2rem;
  color: var(--cds-text-primary, #161616);
  will-change: margin-left;
}

.cds--header ~ .cds--content,
div:has(.cds--header) ~ .cds--content {
  margin-block-start: 3rem;
}

.cds--side-nav ~ .cds--content {
  margin-inline-start: 3rem;
}

.cds--side-nav.cds--side-nav--expanded ~ .cds--content {
  margin-inline-start: 16rem;
}

.cds--header {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  position: fixed;
  z-index: 8000;
  display: flex;
  align-items: center;
  background-color: var(--cds-background, #ffffff);
  block-size: 3rem;
  border-block-end: 1px solid var(--cds-border-subtle);
  inset-block-start: 0;
  inset-inline: 0;
}
.cds--header *,
.cds--header *::before,
.cds--header *::after {
  box-sizing: inherit;
}

.cds--header__action {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  appearance: none;
  background: none;
  cursor: pointer;
  text-align: start;
  inline-size: 100%;
  display: inline-flex;
  border: 0.0625rem solid transparent;
  block-size: 3rem;
  inline-size: 3rem;
  transition: background-color 110ms, border-color 110ms;
}
.cds--header__action *,
.cds--header__action *::before,
.cds--header__action *::after {
  box-sizing: inherit;
}
.cds--header__action::-moz-focus-inner {
  border: 0;
}
@media (max-width: 41.98rem) {
  .cds--header__action {
    min-inline-size: 3rem;
  }
}

.cds--header__global .cds--popover {
  z-index: 8001;
}

.cds--header__action > :first-child {
  margin-block-start: 0;
}

.cds--header__action > svg.cds--navigation-menu-panel-collapse-icon,
.cds--header__action--active > svg.cds--navigation-menu-panel-expand-icon {
  display: none;
}

.cds--header__action--active > svg.cds--navigation-menu-panel-collapse-icon {
  display: inline;
}

.cds--header__action:hover {
  background-color: var(--cds-background-hover, rgba(141, 141, 141, 0.12));
}

.cds--header__action--active {
  background: var(--cds-layer);
  border-block-end: 1px solid transparent;
  border-inline-end: 1px solid var(--cds-border-subtle);
  border-inline-start: 1px solid var(--cds-border-subtle);
}

.cds--header__action--active > svg {
  fill: var(--cds-icon-primary, #161616);
}

.cds--header__action:focus {
  border-color: var(--cds-focus, #0f62fe);
  outline: none;
}

.cds--header__action:active {
  background-color: var(--cds-background-active, rgba(141, 141, 141, 0.5));
}

.cds--header__action.cds--btn--icon-only {
  align-items: center;
  justify-content: center;
}

.cds--btn.cds--btn--icon-only.cds--header__action svg {
  fill: var(--cds-icon-secondary, #525252);
}

.cds--btn.cds--btn--icon-only.cds--header__action:hover svg,
.cds--btn.cds--btn--icon-only.cds--header__action:active svg,
.cds--btn.cds--btn--icon-only.cds--header__action--active svg {
  fill: var(--cds-icon-primary, #161616);
}

.cds--header__menu-trigger > svg {
  fill: var(--cds-icon-primary, #161616);
}

.cds--header__menu-trigger:hover > svg {
  fill: var(--cds-icon-primary, #161616);
}

.cds--header__menu-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 66rem) {
  .cds--header__menu-toggle__hidden {
    display: none;
  }
}

a.cds--header__name {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  display: flex;
  align-items: center;
  padding: 0 2rem 0 1rem;
  border: 0.125rem solid transparent;
  block-size: 100%;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 1.25rem;
  outline: none;
  text-decoration: none;
  transition: border-color 110ms;
  user-select: none;
}
@media (max-width: 41.98rem) {
  a.cds--header__name {
    padding: 0 1rem;
  }
}

a.cds--header__name:focus {
  border-color: var(--cds-focus, #0f62fe);
}

.cds--header__name--prefix {
  font-weight: 400;
}

a.cds--header__name,
a.cds--header__name:hover {
  color: var(--cds-text-primary, #161616);
}

.cds--header__menu-toggle:not(.cds--header__menu-toggle__hidden) ~ .cds--header__name {
  padding-inline-start: 0.5rem;
}

.cds--header__nav {
  position: relative;
  display: none;
  block-size: 100%;
  padding-inline-start: 1rem;
}
@media (min-width: 66rem) {
  .cds--header__nav {
    display: block;
  }
}
.cds--header__nav::before {
  position: absolute;
  display: block;
  background-color: var(--cds-border-subtle);
  block-size: 1.5rem;
  content: "";
  inline-size: 0.0625rem;
  inset-block-start: 50%;
  inset-inline-start: 0;
  transform: translateY(-50%);
}

.cds--header__menu-bar {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: flex;
  padding: 0;
  margin: 0;
  block-size: 100%;
  list-style: none;
}
.cds--header__menu-bar *,
.cds--header__menu-bar *::before,
.cds--header__menu-bar *::after {
  box-sizing: inherit;
}

a.cds--header__menu-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  border: 2px solid transparent;
  background-color: var(--cds-background, #ffffff);
  block-size: 100%;
  color: var(--cds-text-secondary, #525252);
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.125rem;
  text-decoration: none;
  transition: background-color 110ms, border-color 110ms, color 110ms;
  user-select: none;
}

a.cds--header__menu-item:hover {
  background-color: var(--cds-background-hover, rgba(141, 141, 141, 0.12));
  color: var(--cds-text-primary, #161616);
}

.cds--header__action:active,
a.cds--header__menu-item:active {
  background-color: var(--cds-background-active, rgba(141, 141, 141, 0.5));
  color: var(--cds-text-primary, #161616);
}

a.cds--header__menu-item:focus {
  border-color: var(--cds-focus, #0f62fe);
  outline: none;
}

a.cds--header__menu-item:hover > svg,
a.cds--header__menu-item:active > svg {
  fill: var(--cds-icon-primary, #161616);
}

a.cds--header__menu-item[aria-current=page],
.cds--header__menu-item--current {
  color: var(--cds-text-primary, #161616);
}

a.cds--header__menu-item[aria-current=page]::after,
.cds--header__menu-item--current::after {
  position: absolute;
  background-color: var(--cds-border-interactive, #0f62fe);
  block-size: 3px;
  content: "";
  inline-size: calc(100% + 4px);
  inset-block-end: -2px;
  inset-inline-start: -2px;
}

a.cds--header__menu-item[aria-current=page]:focus::after,
.cds--header__menu-item--current:focus::after {
  border: 0;
}

.cds--header__submenu .cds--header__menu a.cds--header__menu-item[aria-current=page]::after,
.cds--header__submenu .cds--header__menu .cds--header__menu-item--current::after {
  background-color: var(--cds-border-interactive, #0f62fe);
  block-size: calc(100% + 4px);
  inline-size: 3px;
  inset-block-start: -2px;
  inset-inline-start: -2px;
}
.cds--header__submenu .cds--header__menu a.cds--header__menu-item[aria-current=page]:focus::after,
.cds--header__submenu .cds--header__menu .cds--header__menu-item--current:focus::after {
  background-color: var(--cds-border-interactive, #0f62fe);
  block-size: calc(100% + 4px);
  inline-size: 5px;
  inset-block-start: -2px;
  inset-inline-start: -2px;
}

a.cds--header__menu-item[aria-current=page]:focus,
a.cds--header__menu-item.cds--header__menu-item--current:focus {
  border: 2px solid var(--cds-focus, #0f62fe);
}

.cds--header__submenu {
  position: relative;
}

.cds--header__menu-title[aria-haspopup=true] {
  position: relative;
}

.cds--header__menu-title[aria-expanded=true] {
  z-index: 8002;
  background-color: var(--cds-layer);
  color: var(--cds-text-secondary, #525252);
}

.cds--header__menu-title[aria-expanded=true] > .cds--header__menu-arrow {
  transform: rotate(180deg);
}

.cds--header__menu {
  display: none;
  padding: 0;
  margin: 0;
  list-style: none;
}

.cds--header__menu-title[aria-expanded=true] + .cds--header__menu {
  position: absolute;
  z-index: 8001;
  display: flex;
  flex-direction: column;
  background-color: var(--cds-layer);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  inline-size: 12.5rem;
  inset-block-end: 0;
  inset-inline-start: 0;
  transform: translateY(100%);
}

.cds--header__menu-title[aria-expanded=true] + .cds--header__menu .cds--header__menu-item {
  background-color: var(--cds-layer);
}

.cds--header__menu-title[aria-expanded=true] + .cds--header__menu .cds--header__menu-item:hover {
  background-color: var(--cds-layer-hover);
  color: var(--cds-text-primary, #161616);
}

.cds--header__menu-title[aria-expanded=true] + .cds--header__menu .cds--header__menu-item:active {
  background-color: var(--cds-layer-active);
  color: var(--cds-text-primary, #161616);
}

.cds--header__menu-title[aria-expanded=true] + .cds--header__menu .cds--header__menu-item.cds--header__menu-item--current {
  background-color: var(--cds-layer-selected);
}
.cds--header__menu-title[aria-expanded=true] + .cds--header__menu .cds--header__menu-item.cds--header__menu-item--current:hover {
  background-color: var(--cds-layer-selected-hover);
}

.cds--header__menu .cds--header__menu-item {
  block-size: 3rem;
}

.cds--header__menu-arrow {
  fill: var(--cds-icon-secondary, #525252);
  margin-inline-start: 0.5rem;
  transition: transform 110ms, fill 110ms;
}

.cds--header__global {
  display: flex;
  flex: 1 1 0%;
  justify-content: flex-end;
  block-size: 100%;
}

.cds--skip-to-content {
  position: absolute;
  overflow: hidden;
  padding: 0;
  border: 0;
  margin: -1px;
  block-size: 1px;
  clip: rect(0, 0, 0, 0);
  inline-size: 1px;
  visibility: inherit;
  white-space: nowrap;
}

.cds--skip-to-content:focus {
  z-index: 9999;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  border: 4px solid var(--cds-focus, #0f62fe);
  background-color: var(--cds-background, #ffffff);
  block-size: 3rem;
  clip: auto;
  color: var(--cds-text-secondary, #525252);
  inline-size: auto;
  inset-block-start: 0;
  inset-inline-start: 0;
  outline: none;
}

.cds--header-panel {
  position: fixed;
  z-index: 8000;
  overflow: hidden;
  border: none;
  background-color: var(--cds-layer);
  color: var(--cds-text-secondary, #525252);
  inline-size: 0;
  inset-block: 3rem 0;
  inset-inline-end: 0;
  transition: width 110ms cubic-bezier(0.2, 0, 1, 0.9);
  will-change: width;
}

.cds--header-panel--expanded {
  border-inline-end: 1px solid var(--cds-border-subtle);
  border-inline-start: 1px solid var(--cds-border-subtle);
  inline-size: 16rem;
  overflow-y: auto;
}

.cds--side-nav {
  position: fixed;
  z-index: 8000;
  overflow: hidden;
  background-color: var(--cds-background, #ffffff);
  color: var(--cds-text-secondary, #525252);
  inline-size: 3rem;
  inset-block: 0;
  inset-inline-start: 0;
  max-inline-size: 16rem;
  transition: inline-size 0.11s cubic-bezier(0.2, 0, 1, 0.9), transform 0.11s cubic-bezier(0.2, 0, 1, 0.9);
  will-change: inline-size;
}

.cds--side-nav--ux {
  inline-size: 16rem;
  inset-block-start: 3rem;
}
@media (max-width: 65.98rem) {
  .cds--side-nav--ux {
    inline-size: 0;
  }
}

.cds--side-nav--rail {
  inline-size: 3rem;
}

.cds--side-nav--hidden {
  inline-size: 0;
}

.cds--side-nav--expanded {
  inline-size: 16rem;
}

.cds--side-nav__overlay {
  position: fixed;
  background-color: transparent;
  block-size: 0;
  inline-size: 0;
  inset-block-start: 3rem;
  inset-inline-start: 0;
  opacity: 0;
  transition: opacity 300ms cubic-bezier(0.5, 0, 0.1, 1), background-color 300ms cubic-bezier(0.5, 0, 0.1, 1);
}

@media (max-width: 65.98rem) {
  .cds--side-nav__overlay-active {
    z-index: 6000;
    background-color: var(--cds-overlay, rgba(22, 22, 22, 0.5));
    block-size: 100vh;
    inline-size: 100vw;
    opacity: 1;
    transition: opacity 300ms cubic-bezier(0.5, 0, 0.1, 1), background-color 300ms cubic-bezier(0.5, 0, 0.1, 1);
  }
}

.cds--header ~ .cds--side-nav {
  block-size: calc(100% - 48px);
  inset-block-start: 3rem;
}

.cds--side-nav--fixed {
  inline-size: 16rem;
}

.cds--side-nav--collapsed {
  inline-size: 16rem;
  transform: translateX(-16rem);
}

.cds--side-nav__navigation {
  display: flex;
  flex-direction: column;
}

.cds--side-nav__items {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  overflow: hidden;
  flex: 1 1 0%;
  padding: 1rem 0 0;
}
.cds--side-nav__items *,
.cds--side-nav__items *::before,
.cds--side-nav__items *::after {
  box-sizing: inherit;
}
.cds--side-nav:hover .cds--side-nav__items, .cds--side-nav--fixed .cds--side-nav__items, .cds--side-nav--expanded .cds--side-nav__items {
  overflow-y: auto;
}

.cds--side-nav--ux .cds--side-nav__items {
  overflow-y: auto;
}

.cds--side-nav__item {
  overflow: hidden;
  block-size: auto;
  inline-size: auto;
}

.cds--side-nav--ux .cds--side-nav__item {
  block-size: auto;
  inline-size: auto;
}

.cds--side-nav__item:not(.cds--side-nav__item--active):hover .cds--side-nav__item:not(.cds--side-nav__item--active) > .cds--side-nav__submenu:hover,
.cds--side-nav__item:not(.cds--side-nav__item--active) > .cds--side-nav__link:hover,
.cds--side-nav__menu a.cds--side-nav__link:not(.cds--side-nav__link--current):not([aria-current=page]):hover,
.cds--side-nav a.cds--header__menu-item:hover,
.cds--side-nav .cds--header__menu-title[aria-expanded=true]:hover {
  background-color: var(--cds-background-hover, rgba(141, 141, 141, 0.12));
  color: var(--cds-text-primary, #161616);
}

.cds--side-nav__item:not(.cds--side-nav__item--active) > .cds--side-nav__link:hover > span,
.cds--side-nav__item:not(.cds--side-nav__item--active) .cds--side-nav__menu-item > .cds--side-nav__link:hover > span {
  color: var(--cds-text-primary, #161616);
}

.cds--side-nav__item--large {
  block-size: auto;
}

.cds--side-nav__divider {
  margin: 0.5rem 1rem;
  background-color: var(--cds-border-subtle);
  block-size: 1px;
  list-style-type: none;
}

.cds--side-nav__divider hr {
  border: none;
}

.cds--side-nav__submenu {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  appearance: none;
  background: none;
  cursor: pointer;
  text-align: start;
  inline-size: 100%;
  font-size: var(--cds-heading-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-heading-compact-01-font-weight, 600);
  line-height: var(--cds-heading-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-heading-compact-01-letter-spacing, 0.16px);
  outline: 2px solid transparent;
  outline-offset: -2px;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  block-size: 2rem;
  color: var(--cds-text-secondary, #525252);
  transition: color 110ms, background-color 110ms, outline 110ms;
  user-select: none;
}
.cds--side-nav__submenu *,
.cds--side-nav__submenu *::before,
.cds--side-nav__submenu *::after {
  box-sizing: inherit;
}
.cds--side-nav__submenu::-moz-focus-inner {
  border: 0;
}

.cds--side-nav__submenu:hover {
  background-color: var(--cds-background-hover, rgba(141, 141, 141, 0.12));
  color: var(--cds-text-primary, #161616);
}

.cds--side-nav__submenu:focus {
  outline: 2px solid var(--cds-focus, #0f62fe);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--side-nav__submenu:focus {
    outline-style: dotted;
  }
}

.cds--side-nav__submenu-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: start;
}

.cds--side-nav__icon.cds--side-nav__submenu-chevron {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.cds--side-nav__submenu-chevron > svg {
  block-size: 1rem;
  inline-size: 1rem;
  transition: transform 110ms;
}

.cds--side-nav__submenu[aria-expanded=true] .cds--side-nav__submenu-chevron > svg {
  transform: rotate(180deg);
}

.cds--side-nav__item--large .cds--side-nav__submenu {
  block-size: 3rem;
}

.cds--side-nav__item--active .cds--side-nav__submenu:hover {
  background-color: var(--cds-background-selected, rgba(141, 141, 141, 0.2));
  color: var(--cds-text-primary, #161616);
}

.cds--side-nav__item--active .cds--side-nav__submenu[aria-expanded=false] {
  position: relative;
  background-color: var(--cds-background-selected, rgba(141, 141, 141, 0.2));
  color: var(--cds-text-primary, #161616);
}
.cds--side-nav__item--active .cds--side-nav__submenu[aria-expanded=false]::before {
  position: absolute;
  background-color: var(--cds-border-interactive, #0f62fe);
  content: "";
  inline-size: 3px;
  inset-block: 0;
  inset-inline-start: 0;
}

.cds--side-nav__item--active .cds--side-nav__submenu-title {
  color: var(--cds-text-primary, #161616);
  font-weight: 600;
}

.cds--side-nav__item--active .cds--side-nav__icon > svg {
  fill: var(--cds-icon-primary, #161616);
}

.cds--side-nav__menu {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: block;
  max-block-size: 0;
  visibility: hidden;
}
.cds--side-nav__menu *,
.cds--side-nav__menu *::before,
.cds--side-nav__menu *::after {
  box-sizing: inherit;
}

.cds--side-nav__submenu[aria-expanded=true] + .cds--side-nav__menu {
  max-block-size: 93.75rem;
  visibility: inherit;
}

.cds--side-nav__menu a.cds--side-nav__link {
  block-size: 2rem;
  font-weight: 400;
  min-block-size: 2rem;
  padding-inline-start: 2rem;
}

.cds--side-nav__item.cds--side-nav__item--icon a.cds--side-nav__link {
  padding-inline-start: 4.5rem;
}

.cds--side-nav__menu a.cds--side-nav__link--current,
.cds--side-nav__menu a.cds--side-nav__link[aria-current=page],
a.cds--side-nav__link--current {
  background-color: var(--cds-background-selected, rgba(141, 141, 141, 0.2));
}
.cds--side-nav__menu a.cds--side-nav__link--current > span,
.cds--side-nav__menu a.cds--side-nav__link[aria-current=page] > span,
a.cds--side-nav__link--current > span {
  color: var(--cds-text-primary, #161616);
  font-weight: 600;
}

a.cds--side-nav__link,
.cds--side-nav a.cds--header__menu-item,
.cds--side-nav .cds--header__menu-title[aria-expanded=true] + .cds--header__menu {
  outline: 2px solid transparent;
  outline-offset: -2px;
  font-size: var(--cds-heading-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-heading-compact-01-font-weight, 600);
  line-height: var(--cds-heading-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-heading-compact-01-letter-spacing, 0.16px);
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  min-block-size: 2rem;
  text-decoration: none;
  transition: color 110ms, background-color 110ms, outline 110ms;
}

.cds--side-nav__item--large a.cds--side-nav__link {
  block-size: 3rem;
}

a.cds--side-nav__link > .cds--side-nav__link-text,
.cds--side-nav a.cds--header__menu-item .cds--text-truncate-end {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--cds-text-secondary, #525252);
  font-size: 0.875rem;
  letter-spacing: 0.1px;
  line-height: 1.25rem;
  user-select: none;
}

a.cds--side-nav__link:focus,
.cds--side-nav a.cds--header__menu-item:focus {
  outline: 2px solid var(--cds-focus, #0f62fe);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  a.cds--side-nav__link:focus,
  .cds--side-nav a.cds--header__menu-item:focus {
    outline-style: dotted;
  }
}

a.cds--side-nav__link[aria-current=page],
a.cds--side-nav__link--current {
  background-color: var(--cds-background-selected, rgba(141, 141, 141, 0.2));
  font-weight: 600;
}

a.cds--side-nav__link[aria-current=page] .cds--side-nav__link-text,
a.cds--side-nav__link--current .cds--side-nav__link-text {
  color: var(--cds-text-primary, #161616);
}

a.cds--side-nav__link[aria-current=page]::before,
a.cds--side-nav__link--current::before {
  position: absolute;
  background-color: var(--cds-border-interactive, #0f62fe);
  content: "";
  inline-size: 3px;
  inset-block: 0;
  inset-inline-start: 0;
}

.cds--side-nav__icon {
  display: flex;
  flex: 0 0 1rem;
  align-items: center;
  justify-content: center;
}

.cds--side-nav__icon:not(.cds--side-nav__submenu-chevron) {
  margin-inline-end: 1.5rem;
}

.cds--side-nav__icon > svg {
  block-size: 1rem;
  fill: var(--cds-icon-secondary, #525252);
  inline-size: 1rem;
}

.cds--side-nav__icon > svg.cds--side-nav-collapse-icon {
  display: none;
}

.cds--side-nav--expanded .cds--side-nav__icon > svg.cds--side-nav-expand-icon {
  display: none;
}

.cds--side-nav--expanded .cds--side-nav__icon > svg.cds--side-nav-collapse-icon {
  display: block;
}

.cds--side-nav--fixed a.cds--side-nav__link,
.cds--side-nav--fixed .cds--side-nav__submenu {
  padding-inline-start: 1rem;
}

.cds--side-nav--fixed .cds--side-nav__item:not(.cds--side-nav__item--icon) .cds--side-nav__menu a.cds--side-nav__link {
  padding-inline-start: 2rem;
}

@media (max-width: 65.98rem) {
  .cds--side-nav .cds--header__nav {
    display: block;
  }
}

.cds--side-nav__header-navigation {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: none;
}
.cds--side-nav__header-navigation *,
.cds--side-nav__header-navigation *::before,
.cds--side-nav__header-navigation *::after {
  box-sizing: inherit;
}
@media (max-width: 65.98rem) {
  .cds--side-nav__header-navigation {
    position: relative;
    display: block;
    margin-block-end: 2rem;
  }
}

.cds--side-nav__header-divider::after {
  position: absolute;
  background: var(--cds-border-subtle);
  block-size: 0.0625rem;
  content: "";
  inline-size: calc(100% - 32px);
  inset-block-end: -1rem;
  inset-inline-start: 1rem;
}

.cds--side-nav a.cds--header__menu-item {
  justify-content: space-between;
  color: var(--cds-text-secondary, #525252);
  white-space: nowrap;
}
.cds--side-nav a.cds--header__menu-item[aria-expanded=true] {
  background-color: transparent;
}

.cds--side-nav .cds--header__menu-title[aria-expanded=true] + .cds--header__menu {
  padding: 0;
  background-color: transparent;
  box-shadow: none;
  inline-size: 100%;
  inset-block-end: inherit;
  transform: none;
}
.cds--side-nav .cds--header__menu-title[aria-expanded=true] + .cds--header__menu li {
  inline-size: 100%;
}
.cds--side-nav .cds--header__menu-title[aria-expanded=true] + .cds--header__menu a.cds--header__menu-item {
  font-weight: 400;
  padding-inline-start: 4.25rem;
}
.cds--side-nav .cds--header__menu-title[aria-expanded=true] + .cds--header__menu a.cds--header__menu-item:hover {
  background-color: var(--cds-background-hover, rgba(141, 141, 141, 0.12));
  color: var(--cds-text-primary, #161616);
}
.cds--side-nav .cds--header__menu-title[aria-expanded=true] + .cds--header__menu a.cds--header__menu-item--current:hover {
  background-color: var(--cds-layer-selected-hover);
}

.cds--side-nav .cds--side-nav__header-navigation a.cds--header__menu-item[aria-current=page]::after,
.cds--side-nav .cds--side-nav__header-navigation .cds--header__menu-item--current::after {
  block-size: calc(100% + 4px);
  inline-size: 3px;
}

.cds--side-nav .cds--header__menu a.cds--header__menu-item {
  block-size: inherit;
}

.cds--side-nav a.cds--header__menu-item:hover .cds--header__menu-arrow,
.cds--side-nav a.cds--header__menu-item:focus .cds--header__menu-arrow,
.cds--side-nav .cds--header__menu-arrow {
  fill: var(--cds-icon-secondary, #525252);
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--side-nav__icon > svg,
  .cds--side-nav a.cds--header__menu-item:hover .cds--header__menu-arrow,
  .cds--side-nav a.cds--header__menu-item:focus .cds--header__menu-arrow,
  .cds--side-nav .cds--header__menu-arrow {
    fill: ButtonText;
  }
}

.cds--switcher {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--cds-text-secondary, #525252);
}
.cds--switcher *,
.cds--switcher *::before,
.cds--switcher *::after {
  box-sizing: inherit;
}

.cds--switcher__item {
  block-size: 2rem;
  inline-size: 100%;
}

.cds--switcher__item:nth-child(1) {
  margin-block-start: 1rem;
}

.cds--switcher__item--divider {
  display: block;
  border: none;
  margin: 0.5rem 1rem;
  background: var(--cds-border-subtle);
  block-size: 1px;
  inline-size: 14rem;
}

.cds--switcher__item-link {
  font-size: var(--cds-heading-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-heading-compact-01-font-weight, 600);
  line-height: var(--cds-heading-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-heading-compact-01-letter-spacing, 0.16px);
  display: block;
  padding: 0.375rem 1rem;
  block-size: 2rem;
  color: var(--cds-text-secondary, #525252);
  text-decoration: none;
}
.cds--switcher__item-link:hover:not(.cds--switcher__item-link--selected) {
  background: var(--cds-layer-hover);
  color: var(--cds-text-primary, #161616);
  cursor: pointer;
}
.cds--switcher__item-link:focus {
  outline: 2px solid var(--cds-focus, #0f62fe);
  outline-offset: -2px;
}
.cds--switcher__item-link:active {
  background: var(--cds-layer-active);
  color: var(--cds-text-primary, #161616);
}

.cds--switcher__item-link--selected {
  background: var(--cds-layer-selected);
  color: var(--cds-text-primary, #161616);
}

.cds--content {
  padding: 2rem;
  color: var(--cds-text-primary, #161616);
  will-change: margin-left;
}

.cds--header ~ .cds--content,
div:has(.cds--header) ~ .cds--content {
  margin-block-start: 3rem;
}

.cds--side-nav ~ .cds--content {
  margin-inline-start: 3rem;
}

.cds--side-nav.cds--side-nav--expanded ~ .cds--content {
  margin-inline-start: 16rem;
}

.cds--header {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  position: fixed;
  z-index: 8000;
  display: flex;
  align-items: center;
  background-color: var(--cds-background, #ffffff);
  block-size: 3rem;
  border-block-end: 1px solid var(--cds-border-subtle);
  inset-block-start: 0;
  inset-inline: 0;
}
.cds--header *,
.cds--header *::before,
.cds--header *::after {
  box-sizing: inherit;
}

.cds--header__action {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  appearance: none;
  background: none;
  cursor: pointer;
  text-align: start;
  inline-size: 100%;
  display: inline-flex;
  border: 0.0625rem solid transparent;
  block-size: 3rem;
  inline-size: 3rem;
  transition: background-color 110ms, border-color 110ms;
}
.cds--header__action *,
.cds--header__action *::before,
.cds--header__action *::after {
  box-sizing: inherit;
}
.cds--header__action::-moz-focus-inner {
  border: 0;
}
@media (max-width: 41.98rem) {
  .cds--header__action {
    min-inline-size: 3rem;
  }
}

.cds--header__global .cds--popover {
  z-index: 8001;
}

.cds--header__action > :first-child {
  margin-block-start: 0;
}

.cds--header__action > svg.cds--navigation-menu-panel-collapse-icon,
.cds--header__action--active > svg.cds--navigation-menu-panel-expand-icon {
  display: none;
}

.cds--header__action--active > svg.cds--navigation-menu-panel-collapse-icon {
  display: inline;
}

.cds--header__action:hover {
  background-color: var(--cds-background-hover, rgba(141, 141, 141, 0.12));
}

.cds--header__action--active {
  background: var(--cds-layer);
  border-block-end: 1px solid transparent;
  border-inline-end: 1px solid var(--cds-border-subtle);
  border-inline-start: 1px solid var(--cds-border-subtle);
}

.cds--header__action--active > svg {
  fill: var(--cds-icon-primary, #161616);
}

.cds--header__action:focus {
  border-color: var(--cds-focus, #0f62fe);
  outline: none;
}

.cds--header__action:active {
  background-color: var(--cds-background-active, rgba(141, 141, 141, 0.5));
}

.cds--header__action.cds--btn--icon-only {
  align-items: center;
  justify-content: center;
}

.cds--btn.cds--btn--icon-only.cds--header__action svg {
  fill: var(--cds-icon-secondary, #525252);
}

.cds--btn.cds--btn--icon-only.cds--header__action:hover svg,
.cds--btn.cds--btn--icon-only.cds--header__action:active svg,
.cds--btn.cds--btn--icon-only.cds--header__action--active svg {
  fill: var(--cds-icon-primary, #161616);
}

.cds--header__menu-trigger > svg {
  fill: var(--cds-icon-primary, #161616);
}

.cds--header__menu-trigger:hover > svg {
  fill: var(--cds-icon-primary, #161616);
}

.cds--header__menu-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 66rem) {
  .cds--header__menu-toggle__hidden {
    display: none;
  }
}

a.cds--header__name {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  display: flex;
  align-items: center;
  padding: 0 2rem 0 1rem;
  border: 0.125rem solid transparent;
  block-size: 100%;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 1.25rem;
  outline: none;
  text-decoration: none;
  transition: border-color 110ms;
  user-select: none;
}
@media (max-width: 41.98rem) {
  a.cds--header__name {
    padding: 0 1rem;
  }
}

a.cds--header__name:focus {
  border-color: var(--cds-focus, #0f62fe);
}

.cds--header__name--prefix {
  font-weight: 400;
}

a.cds--header__name,
a.cds--header__name:hover {
  color: var(--cds-text-primary, #161616);
}

.cds--header__menu-toggle:not(.cds--header__menu-toggle__hidden) ~ .cds--header__name {
  padding-inline-start: 0.5rem;
}

.cds--header__nav {
  position: relative;
  display: none;
  block-size: 100%;
  padding-inline-start: 1rem;
}
@media (min-width: 66rem) {
  .cds--header__nav {
    display: block;
  }
}
.cds--header__nav::before {
  position: absolute;
  display: block;
  background-color: var(--cds-border-subtle);
  block-size: 1.5rem;
  content: "";
  inline-size: 0.0625rem;
  inset-block-start: 50%;
  inset-inline-start: 0;
  transform: translateY(-50%);
}

.cds--header__menu-bar {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: flex;
  padding: 0;
  margin: 0;
  block-size: 100%;
  list-style: none;
}
.cds--header__menu-bar *,
.cds--header__menu-bar *::before,
.cds--header__menu-bar *::after {
  box-sizing: inherit;
}

a.cds--header__menu-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  border: 2px solid transparent;
  background-color: var(--cds-background, #ffffff);
  block-size: 100%;
  color: var(--cds-text-secondary, #525252);
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.125rem;
  text-decoration: none;
  transition: background-color 110ms, border-color 110ms, color 110ms;
  user-select: none;
}

a.cds--header__menu-item:hover {
  background-color: var(--cds-background-hover, rgba(141, 141, 141, 0.12));
  color: var(--cds-text-primary, #161616);
}

.cds--header__action:active,
a.cds--header__menu-item:active {
  background-color: var(--cds-background-active, rgba(141, 141, 141, 0.5));
  color: var(--cds-text-primary, #161616);
}

a.cds--header__menu-item:focus {
  border-color: var(--cds-focus, #0f62fe);
  outline: none;
}

a.cds--header__menu-item:hover > svg,
a.cds--header__menu-item:active > svg {
  fill: var(--cds-icon-primary, #161616);
}

a.cds--header__menu-item[aria-current=page],
.cds--header__menu-item--current {
  color: var(--cds-text-primary, #161616);
}

a.cds--header__menu-item[aria-current=page]::after,
.cds--header__menu-item--current::after {
  position: absolute;
  background-color: var(--cds-border-interactive, #0f62fe);
  block-size: 3px;
  content: "";
  inline-size: calc(100% + 4px);
  inset-block-end: -2px;
  inset-inline-start: -2px;
}

a.cds--header__menu-item[aria-current=page]:focus::after,
.cds--header__menu-item--current:focus::after {
  border: 0;
}

.cds--header__submenu .cds--header__menu a.cds--header__menu-item[aria-current=page]::after,
.cds--header__submenu .cds--header__menu .cds--header__menu-item--current::after {
  background-color: var(--cds-border-interactive, #0f62fe);
  block-size: calc(100% + 4px);
  inline-size: 3px;
  inset-block-start: -2px;
  inset-inline-start: -2px;
}
.cds--header__submenu .cds--header__menu a.cds--header__menu-item[aria-current=page]:focus::after,
.cds--header__submenu .cds--header__menu .cds--header__menu-item--current:focus::after {
  background-color: var(--cds-border-interactive, #0f62fe);
  block-size: calc(100% + 4px);
  inline-size: 5px;
  inset-block-start: -2px;
  inset-inline-start: -2px;
}

a.cds--header__menu-item[aria-current=page]:focus,
a.cds--header__menu-item.cds--header__menu-item--current:focus {
  border: 2px solid var(--cds-focus, #0f62fe);
}

.cds--header__submenu {
  position: relative;
}

.cds--header__menu-title[aria-haspopup=true] {
  position: relative;
}

.cds--header__menu-title[aria-expanded=true] {
  z-index: 8002;
  background-color: var(--cds-layer);
  color: var(--cds-text-secondary, #525252);
}

.cds--header__menu-title[aria-expanded=true] > .cds--header__menu-arrow {
  transform: rotate(180deg);
}

.cds--header__menu {
  display: none;
  padding: 0;
  margin: 0;
  list-style: none;
}

.cds--header__menu-title[aria-expanded=true] + .cds--header__menu {
  position: absolute;
  z-index: 8001;
  display: flex;
  flex-direction: column;
  background-color: var(--cds-layer);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  inline-size: 12.5rem;
  inset-block-end: 0;
  inset-inline-start: 0;
  transform: translateY(100%);
}

.cds--header__menu-title[aria-expanded=true] + .cds--header__menu .cds--header__menu-item {
  background-color: var(--cds-layer);
}

.cds--header__menu-title[aria-expanded=true] + .cds--header__menu .cds--header__menu-item:hover {
  background-color: var(--cds-layer-hover);
  color: var(--cds-text-primary, #161616);
}

.cds--header__menu-title[aria-expanded=true] + .cds--header__menu .cds--header__menu-item:active {
  background-color: var(--cds-layer-active);
  color: var(--cds-text-primary, #161616);
}

.cds--header__menu-title[aria-expanded=true] + .cds--header__menu .cds--header__menu-item.cds--header__menu-item--current {
  background-color: var(--cds-layer-selected);
}
.cds--header__menu-title[aria-expanded=true] + .cds--header__menu .cds--header__menu-item.cds--header__menu-item--current:hover {
  background-color: var(--cds-layer-selected-hover);
}

.cds--header__menu .cds--header__menu-item {
  block-size: 3rem;
}

.cds--header__menu-arrow {
  fill: var(--cds-icon-secondary, #525252);
  margin-inline-start: 0.5rem;
  transition: transform 110ms, fill 110ms;
}

.cds--header__global {
  display: flex;
  flex: 1 1 0%;
  justify-content: flex-end;
  block-size: 100%;
}

.cds--skip-to-content {
  position: absolute;
  overflow: hidden;
  padding: 0;
  border: 0;
  margin: -1px;
  block-size: 1px;
  clip: rect(0, 0, 0, 0);
  inline-size: 1px;
  visibility: inherit;
  white-space: nowrap;
}

.cds--skip-to-content:focus {
  z-index: 9999;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  border: 4px solid var(--cds-focus, #0f62fe);
  background-color: var(--cds-background, #ffffff);
  block-size: 3rem;
  clip: auto;
  color: var(--cds-text-secondary, #525252);
  inline-size: auto;
  inset-block-start: 0;
  inset-inline-start: 0;
  outline: none;
}

.cds--header-panel {
  position: fixed;
  z-index: 8000;
  overflow: hidden;
  border: none;
  background-color: var(--cds-layer);
  color: var(--cds-text-secondary, #525252);
  inline-size: 0;
  inset-block: 3rem 0;
  inset-inline-end: 0;
  transition: width 110ms cubic-bezier(0.2, 0, 1, 0.9);
  will-change: width;
}

.cds--header-panel--expanded {
  border-inline-end: 1px solid var(--cds-border-subtle);
  border-inline-start: 1px solid var(--cds-border-subtle);
  inline-size: 16rem;
  overflow-y: auto;
}

.cds--side-nav {
  position: fixed;
  z-index: 8000;
  overflow: hidden;
  background-color: var(--cds-background, #ffffff);
  color: var(--cds-text-secondary, #525252);
  inline-size: 3rem;
  inset-block: 0;
  inset-inline-start: 0;
  max-inline-size: 16rem;
  transition: inline-size 0.11s cubic-bezier(0.2, 0, 1, 0.9), transform 0.11s cubic-bezier(0.2, 0, 1, 0.9);
  will-change: inline-size;
}

.cds--side-nav--ux {
  inline-size: 16rem;
  inset-block-start: 3rem;
}
@media (max-width: 65.98rem) {
  .cds--side-nav--ux {
    inline-size: 0;
  }
}

.cds--side-nav--rail {
  inline-size: 3rem;
}

.cds--side-nav--hidden {
  inline-size: 0;
}

.cds--side-nav--expanded {
  inline-size: 16rem;
}

.cds--side-nav__overlay {
  position: fixed;
  background-color: transparent;
  block-size: 0;
  inline-size: 0;
  inset-block-start: 3rem;
  inset-inline-start: 0;
  opacity: 0;
  transition: opacity 300ms cubic-bezier(0.5, 0, 0.1, 1), background-color 300ms cubic-bezier(0.5, 0, 0.1, 1);
}

@media (max-width: 65.98rem) {
  .cds--side-nav__overlay-active {
    z-index: 6000;
    background-color: var(--cds-overlay, rgba(22, 22, 22, 0.5));
    block-size: 100vh;
    inline-size: 100vw;
    opacity: 1;
    transition: opacity 300ms cubic-bezier(0.5, 0, 0.1, 1), background-color 300ms cubic-bezier(0.5, 0, 0.1, 1);
  }
}

.cds--header ~ .cds--side-nav {
  block-size: calc(100% - 48px);
  inset-block-start: 3rem;
}

.cds--side-nav--fixed {
  inline-size: 16rem;
}

.cds--side-nav--collapsed {
  inline-size: 16rem;
  transform: translateX(-16rem);
}

.cds--side-nav__navigation {
  display: flex;
  flex-direction: column;
}

.cds--side-nav__items {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  overflow: hidden;
  flex: 1 1 0%;
  padding: 1rem 0 0;
}
.cds--side-nav__items *,
.cds--side-nav__items *::before,
.cds--side-nav__items *::after {
  box-sizing: inherit;
}
.cds--side-nav:hover .cds--side-nav__items, .cds--side-nav--fixed .cds--side-nav__items, .cds--side-nav--expanded .cds--side-nav__items {
  overflow-y: auto;
}

.cds--side-nav--ux .cds--side-nav__items {
  overflow-y: auto;
}

.cds--side-nav__item {
  overflow: hidden;
  block-size: auto;
  inline-size: auto;
}

.cds--side-nav--ux .cds--side-nav__item {
  block-size: auto;
  inline-size: auto;
}

.cds--side-nav__item:not(.cds--side-nav__item--active):hover .cds--side-nav__item:not(.cds--side-nav__item--active) > .cds--side-nav__submenu:hover,
.cds--side-nav__item:not(.cds--side-nav__item--active) > .cds--side-nav__link:hover,
.cds--side-nav__menu a.cds--side-nav__link:not(.cds--side-nav__link--current):not([aria-current=page]):hover,
.cds--side-nav a.cds--header__menu-item:hover,
.cds--side-nav .cds--header__menu-title[aria-expanded=true]:hover {
  background-color: var(--cds-background-hover, rgba(141, 141, 141, 0.12));
  color: var(--cds-text-primary, #161616);
}

.cds--side-nav__item:not(.cds--side-nav__item--active) > .cds--side-nav__link:hover > span,
.cds--side-nav__item:not(.cds--side-nav__item--active) .cds--side-nav__menu-item > .cds--side-nav__link:hover > span {
  color: var(--cds-text-primary, #161616);
}

.cds--side-nav__item--large {
  block-size: auto;
}

.cds--side-nav__divider {
  margin: 0.5rem 1rem;
  background-color: var(--cds-border-subtle);
  block-size: 1px;
  list-style-type: none;
}

.cds--side-nav__divider hr {
  border: none;
}

.cds--side-nav__submenu {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  appearance: none;
  background: none;
  cursor: pointer;
  text-align: start;
  inline-size: 100%;
  font-size: var(--cds-heading-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-heading-compact-01-font-weight, 600);
  line-height: var(--cds-heading-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-heading-compact-01-letter-spacing, 0.16px);
  outline: 2px solid transparent;
  outline-offset: -2px;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  block-size: 2rem;
  color: var(--cds-text-secondary, #525252);
  transition: color 110ms, background-color 110ms, outline 110ms;
  user-select: none;
}
.cds--side-nav__submenu *,
.cds--side-nav__submenu *::before,
.cds--side-nav__submenu *::after {
  box-sizing: inherit;
}
.cds--side-nav__submenu::-moz-focus-inner {
  border: 0;
}

.cds--side-nav__submenu:hover {
  background-color: var(--cds-background-hover, rgba(141, 141, 141, 0.12));
  color: var(--cds-text-primary, #161616);
}

.cds--side-nav__submenu:focus {
  outline: 2px solid var(--cds-focus, #0f62fe);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--side-nav__submenu:focus {
    outline-style: dotted;
  }
}

.cds--side-nav__submenu-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: start;
}

.cds--side-nav__icon.cds--side-nav__submenu-chevron {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.cds--side-nav__submenu-chevron > svg {
  block-size: 1rem;
  inline-size: 1rem;
  transition: transform 110ms;
}

.cds--side-nav__submenu[aria-expanded=true] .cds--side-nav__submenu-chevron > svg {
  transform: rotate(180deg);
}

.cds--side-nav__item--large .cds--side-nav__submenu {
  block-size: 3rem;
}

.cds--side-nav__item--active .cds--side-nav__submenu:hover {
  background-color: var(--cds-background-selected, rgba(141, 141, 141, 0.2));
  color: var(--cds-text-primary, #161616);
}

.cds--side-nav__item--active .cds--side-nav__submenu[aria-expanded=false] {
  position: relative;
  background-color: var(--cds-background-selected, rgba(141, 141, 141, 0.2));
  color: var(--cds-text-primary, #161616);
}
.cds--side-nav__item--active .cds--side-nav__submenu[aria-expanded=false]::before {
  position: absolute;
  background-color: var(--cds-border-interactive, #0f62fe);
  content: "";
  inline-size: 3px;
  inset-block: 0;
  inset-inline-start: 0;
}

.cds--side-nav__item--active .cds--side-nav__submenu-title {
  color: var(--cds-text-primary, #161616);
  font-weight: 600;
}

.cds--side-nav__item--active .cds--side-nav__icon > svg {
  fill: var(--cds-icon-primary, #161616);
}

.cds--side-nav__menu {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: block;
  max-block-size: 0;
  visibility: hidden;
}
.cds--side-nav__menu *,
.cds--side-nav__menu *::before,
.cds--side-nav__menu *::after {
  box-sizing: inherit;
}

.cds--side-nav__submenu[aria-expanded=true] + .cds--side-nav__menu {
  max-block-size: 93.75rem;
  visibility: inherit;
}

.cds--side-nav__menu a.cds--side-nav__link {
  block-size: 2rem;
  font-weight: 400;
  min-block-size: 2rem;
  padding-inline-start: 2rem;
}

.cds--side-nav__item.cds--side-nav__item--icon a.cds--side-nav__link {
  padding-inline-start: 4.5rem;
}

.cds--side-nav__menu a.cds--side-nav__link--current,
.cds--side-nav__menu a.cds--side-nav__link[aria-current=page],
a.cds--side-nav__link--current {
  background-color: var(--cds-background-selected, rgba(141, 141, 141, 0.2));
}
.cds--side-nav__menu a.cds--side-nav__link--current > span,
.cds--side-nav__menu a.cds--side-nav__link[aria-current=page] > span,
a.cds--side-nav__link--current > span {
  color: var(--cds-text-primary, #161616);
  font-weight: 600;
}

a.cds--side-nav__link,
.cds--side-nav a.cds--header__menu-item,
.cds--side-nav .cds--header__menu-title[aria-expanded=true] + .cds--header__menu {
  outline: 2px solid transparent;
  outline-offset: -2px;
  font-size: var(--cds-heading-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-heading-compact-01-font-weight, 600);
  line-height: var(--cds-heading-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-heading-compact-01-letter-spacing, 0.16px);
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  min-block-size: 2rem;
  text-decoration: none;
  transition: color 110ms, background-color 110ms, outline 110ms;
}

.cds--side-nav__item--large a.cds--side-nav__link {
  block-size: 3rem;
}

a.cds--side-nav__link > .cds--side-nav__link-text,
.cds--side-nav a.cds--header__menu-item .cds--text-truncate-end {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--cds-text-secondary, #525252);
  font-size: 0.875rem;
  letter-spacing: 0.1px;
  line-height: 1.25rem;
  user-select: none;
}

a.cds--side-nav__link:focus,
.cds--side-nav a.cds--header__menu-item:focus {
  outline: 2px solid var(--cds-focus, #0f62fe);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  a.cds--side-nav__link:focus,
  .cds--side-nav a.cds--header__menu-item:focus {
    outline-style: dotted;
  }
}

a.cds--side-nav__link[aria-current=page],
a.cds--side-nav__link--current {
  background-color: var(--cds-background-selected, rgba(141, 141, 141, 0.2));
  font-weight: 600;
}

a.cds--side-nav__link[aria-current=page] .cds--side-nav__link-text,
a.cds--side-nav__link--current .cds--side-nav__link-text {
  color: var(--cds-text-primary, #161616);
}

a.cds--side-nav__link[aria-current=page]::before,
a.cds--side-nav__link--current::before {
  position: absolute;
  background-color: var(--cds-border-interactive, #0f62fe);
  content: "";
  inline-size: 3px;
  inset-block: 0;
  inset-inline-start: 0;
}

.cds--side-nav__icon {
  display: flex;
  flex: 0 0 1rem;
  align-items: center;
  justify-content: center;
}

.cds--side-nav__icon:not(.cds--side-nav__submenu-chevron) {
  margin-inline-end: 1.5rem;
}

.cds--side-nav__icon > svg {
  block-size: 1rem;
  fill: var(--cds-icon-secondary, #525252);
  inline-size: 1rem;
}

.cds--side-nav__icon > svg.cds--side-nav-collapse-icon {
  display: none;
}

.cds--side-nav--expanded .cds--side-nav__icon > svg.cds--side-nav-expand-icon {
  display: none;
}

.cds--side-nav--expanded .cds--side-nav__icon > svg.cds--side-nav-collapse-icon {
  display: block;
}

.cds--side-nav--fixed a.cds--side-nav__link,
.cds--side-nav--fixed .cds--side-nav__submenu {
  padding-inline-start: 1rem;
}

.cds--side-nav--fixed .cds--side-nav__item:not(.cds--side-nav__item--icon) .cds--side-nav__menu a.cds--side-nav__link {
  padding-inline-start: 2rem;
}

@media (max-width: 65.98rem) {
  .cds--side-nav .cds--header__nav {
    display: block;
  }
}

.cds--side-nav__header-navigation {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: none;
}
.cds--side-nav__header-navigation *,
.cds--side-nav__header-navigation *::before,
.cds--side-nav__header-navigation *::after {
  box-sizing: inherit;
}
@media (max-width: 65.98rem) {
  .cds--side-nav__header-navigation {
    position: relative;
    display: block;
    margin-block-end: 2rem;
  }
}

.cds--side-nav__header-divider::after {
  position: absolute;
  background: var(--cds-border-subtle);
  block-size: 0.0625rem;
  content: "";
  inline-size: calc(100% - 32px);
  inset-block-end: -1rem;
  inset-inline-start: 1rem;
}

.cds--side-nav a.cds--header__menu-item {
  justify-content: space-between;
  color: var(--cds-text-secondary, #525252);
  white-space: nowrap;
}
.cds--side-nav a.cds--header__menu-item[aria-expanded=true] {
  background-color: transparent;
}

.cds--side-nav .cds--header__menu-title[aria-expanded=true] + .cds--header__menu {
  padding: 0;
  background-color: transparent;
  box-shadow: none;
  inline-size: 100%;
  inset-block-end: inherit;
  transform: none;
}
.cds--side-nav .cds--header__menu-title[aria-expanded=true] + .cds--header__menu li {
  inline-size: 100%;
}
.cds--side-nav .cds--header__menu-title[aria-expanded=true] + .cds--header__menu a.cds--header__menu-item {
  font-weight: 400;
  padding-inline-start: 4.25rem;
}
.cds--side-nav .cds--header__menu-title[aria-expanded=true] + .cds--header__menu a.cds--header__menu-item:hover {
  background-color: var(--cds-background-hover, rgba(141, 141, 141, 0.12));
  color: var(--cds-text-primary, #161616);
}
.cds--side-nav .cds--header__menu-title[aria-expanded=true] + .cds--header__menu a.cds--header__menu-item--current:hover {
  background-color: var(--cds-layer-selected-hover);
}

.cds--side-nav .cds--side-nav__header-navigation a.cds--header__menu-item[aria-current=page]::after,
.cds--side-nav .cds--side-nav__header-navigation .cds--header__menu-item--current::after {
  block-size: calc(100% + 4px);
  inline-size: 3px;
}

.cds--side-nav .cds--header__menu a.cds--header__menu-item {
  block-size: inherit;
}

.cds--side-nav a.cds--header__menu-item:hover .cds--header__menu-arrow,
.cds--side-nav a.cds--header__menu-item:focus .cds--header__menu-arrow,
.cds--side-nav .cds--header__menu-arrow {
  fill: var(--cds-icon-secondary, #525252);
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--side-nav__icon > svg,
  .cds--side-nav a.cds--header__menu-item:hover .cds--header__menu-arrow,
  .cds--side-nav a.cds--header__menu-item:focus .cds--header__menu-arrow,
  .cds--side-nav .cds--header__menu-arrow {
    fill: ButtonText;
  }
}

.cds--switcher {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--cds-text-secondary, #525252);
}
.cds--switcher *,
.cds--switcher *::before,
.cds--switcher *::after {
  box-sizing: inherit;
}

.cds--switcher__item {
  block-size: 2rem;
  inline-size: 100%;
}

.cds--switcher__item:nth-child(1) {
  margin-block-start: 1rem;
}

.cds--switcher__item--divider {
  display: block;
  border: none;
  margin: 0.5rem 1rem;
  background: var(--cds-border-subtle);
  block-size: 1px;
  inline-size: 14rem;
}

.cds--switcher__item-link {
  font-size: var(--cds-heading-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-heading-compact-01-font-weight, 600);
  line-height: var(--cds-heading-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-heading-compact-01-letter-spacing, 0.16px);
  display: block;
  padding: 0.375rem 1rem;
  block-size: 2rem;
  color: var(--cds-text-secondary, #525252);
  text-decoration: none;
}
.cds--switcher__item-link:hover:not(.cds--switcher__item-link--selected) {
  background: var(--cds-layer-hover);
  color: var(--cds-text-primary, #161616);
  cursor: pointer;
}
.cds--switcher__item-link:focus {
  outline: 2px solid var(--cds-focus, #0f62fe);
  outline-offset: -2px;
}
.cds--switcher__item-link:active {
  background: var(--cds-layer-active);
  color: var(--cds-text-primary, #161616);
}

.cds--switcher__item-link--selected {
  background: var(--cds-layer-selected);
  color: var(--cds-text-primary, #161616);
}

.cds--modal {
  position: fixed;
  z-index: 9000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cds-overlay, rgba(22, 22, 22, 0.5));
  block-size: 100vh;
  content: "";
  inline-size: 100vw;
  inset-block-start: 0;
  inset-inline-start: 0;
  opacity: 0;
  transition: opacity 240ms cubic-bezier(0.4, 0.14, 1, 1), visibility 0ms linear 240ms;
  visibility: hidden;
}
.cds--modal.is-visible {
  opacity: 1;
  transition: opacity 240ms cubic-bezier(0, 0, 0.3, 1), visibility 0ms linear;
  visibility: inherit;
}
@media screen and (prefers-reduced-motion: reduce) {
  .cds--modal.is-visible {
    transition: none;
  }
}
.cds--modal .cds--text-input--fluid .cds--text-input,
.cds--modal .cds--text-area--fluid .cds--text-area__wrapper,
.cds--modal .cds--text-area--fluid .cds--text-area,
.cds--modal .cds--search--fluid .cds--search-input,
.cds--modal .cds--select--fluid .cds--select-input,
.cds--modal .cds--text-area--fluid .cds--text-area__wrapper[data-invalid] .cds--text-area__divider + .cds--form-requirement,
.cds--modal .cds--list-box__wrapper--fluid .cds--list-box,
.cds--modal .cds--list-box__wrapper--fluid.cds--list-box__wrapper,
.cds--modal .cds--number-input--fluid input[type=number],
.cds--modal .cds--number-input--fluid .cds--number__control-btn::before,
.cds--modal .cds--number-input--fluid .cds--number__control-btn::after,
.cds--modal .cds--date-picker--fluid .ccdsds--date-picker-input__wrapper .cds--date-picker__input {
  background-color: var(--cds-field-01, #f4f4f4);
}
.cds--modal .cds--list-box__wrapper--fluid .cds--list-box__menu {
  background-color: var(--cds-layer-01, #f4f4f4);
}
.cds--modal .cds--list-box__menu-item:hover {
  background-color: var(--cds-layer-hover-02, #e8e8e8);
}
.cds--modal .cds--list-box__menu-item--active {
  background-color: var(--cds-layer-selected-02, #e0e0e0);
}
.cds--modal .cds--list-box__menu-item--active:hover {
  background-color: var(--cds-layer-selected-hover-02, #d1d1d1);
}
.cds--modal .cds--number-input--fluid .cds--number__control-btn:hover::before,
.cds--modal .cds--number-input--fluid .cds--number__control-btn:hover::after {
  background-color: var(--cds-field-hover);
}
.cds--modal .cds--number-input--fluid .cds--number__control-btn:focus::before,
.cds--modal .cds--number-input--fluid .cds--number__control-btn:focus::after {
  border-inline-start: 2px solid var(--cds-focus, #0f62fe);
}

.cds--modal.is-visible .cds--modal-container {
  transform: translate3d(0, 0, 0);
  transition: transform 240ms cubic-bezier(0, 0, 0.3, 1);
}

.cds--modal-container {
  position: fixed;
  display: grid;
  background-color: var(--cds-layer);
  block-size: 100%;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  inline-size: 100%;
  inset-block-start: 0;
  max-block-size: 100%;
  outline: 3px solid transparent;
  outline-offset: -3px;
  transform: translate3d(0, -24px, 0);
  transform-origin: top center;
  transition: transform 240ms cubic-bezier(0.4, 0.14, 1, 1);
}
@media (min-width: 42rem) {
  .cds--modal-container {
    position: static;
    block-size: auto;
    inline-size: 84%;
    max-block-size: 90%;
  }
}
@media (min-width: 66rem) {
  .cds--modal-container {
    inline-size: 60%;
    max-block-size: 84%;
  }
}
@media (min-width: 82rem) {
  .cds--modal-container {
    inline-size: 48%;
  }
}
.cds--modal-container .cds--modal-container-body {
  display: contents;
}

.cds--modal-content {
  font-size: var(--cds-body-01-font-size, 0.875rem);
  font-weight: var(--cds-body-01-font-weight, 400);
  line-height: var(--cds-body-01-line-height, 1.42857);
  letter-spacing: var(--cds-body-01-letter-spacing, 0.16px);
  position: relative;
  color: var(--cds-text-primary, #161616);
  font-weight: 400;
  grid-column: 1/-1;
  grid-row: 2/-2;
  overflow-y: auto;
  padding-block: 0.5rem 3rem;
  padding-inline: 1rem 1rem;
}
.cds--modal-content:focus {
  outline: 2px solid var(--cds-focus, #0f62fe);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--modal-content:focus {
    outline-style: dotted;
  }
}

.cds--modal-content .cds--form--fluid {
  margin-inline: -2rem;
}

.cds--modal-content > p,
.cds--modal-content__regular-content {
  font-size: var(--cds-body-01-font-size, 0.875rem);
  font-weight: var(--cds-body-01-font-weight, 400);
  line-height: var(--cds-body-01-line-height, 1.42857);
  letter-spacing: var(--cds-body-01-letter-spacing, 0.16px);
  padding-inline-end: calc(20% - 2rem);
}

.cds--modal-content--with-form {
  padding-inline-end: 1rem;
}

.cds--modal-header {
  grid-column: 1/-1;
  grid-row: 1/1;
  margin-block-end: 0.5rem;
  padding-block-start: 1rem;
  padding-inline: 1rem 3rem;
}

.cds--modal-header__label {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-size: var(--cds-label-01-font-size, 0.75rem);
  font-weight: var(--cds-label-01-font-weight, 400);
  line-height: var(--cds-label-01-line-height, 1.33333);
  letter-spacing: var(--cds-label-01-letter-spacing, 0.32px);
  color: var(--cds-text-secondary, #525252);
  margin-block-end: 0.25rem;
}
.cds--modal-header__label *,
.cds--modal-header__label *::before,
.cds--modal-header__label *::after {
  box-sizing: inherit;
}

.cds--modal-header__heading {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-size: var(--cds-heading-03-font-size, 1.25rem);
  font-weight: var(--cds-heading-03-font-weight, 400);
  line-height: var(--cds-heading-03-line-height, 1.4);
  letter-spacing: var(--cds-heading-03-letter-spacing, 0);
  color: var(--cds-text-primary, #161616);
  padding-inline-end: calc(20% - 3rem);
}
.cds--modal-header__heading *,
.cds--modal-header__heading *::before,
.cds--modal-header__heading *::after {
  box-sizing: inherit;
}

.cds--modal-container--xs .cds--modal-content__regular-content {
  padding-inline-end: 1rem;
}
.cds--modal-container--xs .cds--modal-content > p {
  padding-inline-end: 0;
}
@media (min-width: 42rem) {
  .cds--modal-container--xs {
    inline-size: 48%;
  }
}
@media (min-width: 66rem) {
  .cds--modal-container--xs {
    inline-size: 32%;
    max-block-size: 48%;
  }
}
@media (min-width: 82rem) {
  .cds--modal-container--xs {
    inline-size: 24%;
  }
}

.cds--modal-container--sm .cds--modal-content__regular-content {
  padding-inline-end: 1rem;
}
.cds--modal-container--sm .cds--modal-content > p {
  padding-inline-end: 0;
}
@media (min-width: 42rem) {
  .cds--modal-container--sm {
    inline-size: 60%;
  }
}
@media (min-width: 66rem) {
  .cds--modal-container--sm {
    inline-size: 42%;
    max-block-size: 72%;
  }
  .cds--modal-container--sm .cds--modal-content > p,
  .cds--modal-container--sm .cds--modal-content__regular-content {
    padding-inline-end: 20%;
  }
}
@media (min-width: 82rem) {
  .cds--modal-container--sm {
    inline-size: 36%;
  }
}

@media (min-width: 42rem) {
  .cds--modal-container--lg {
    inline-size: 96%;
  }
}
@media (min-width: 66rem) {
  .cds--modal-container--lg {
    inline-size: 84%;
    max-block-size: 96%;
  }
}
@media (min-width: 82rem) {
  .cds--modal-container--lg {
    inline-size: 72%;
  }
}

.cds--modal-scroll-content {
  border-block-end: 2px solid transparent;
  mask-image: linear-gradient(to bottom, var(--cds-layer) calc(100% - 80px), transparent calc(100% - 48px), transparent 100%), linear-gradient(to left, var(--cds-layer) 0, 16px, transparent 16px), linear-gradient(to right, var(--cds-layer) 0, 2px, transparent 2px), linear-gradient(to top, var(--cds-layer) 0, 2px, transparent 2px);
}

.cds--modal-scroll-content:has(.cds--autoalign) {
  mask-image: none;
}

.cds--modal-scroll-content > *:last-child {
  margin-block-end: 1.5rem;
}

.cds--modal-footer {
  display: flex;
  justify-content: flex-end;
  block-size: 4rem;
  grid-column: 1/-1;
  grid-row: -1/-1;
  margin-block-start: auto;
}

.cds--modal-footer .cds--btn {
  flex: 0 1 50%;
  align-items: baseline;
  margin: 0;
  block-size: 4rem;
  max-inline-size: none;
}

.cds--modal-footer .cds--btn:not(.cds--skeleton) {
  padding-block: 0.875rem 2rem;
}

.cds--modal-footer--three-button .cds--btn {
  flex: 0 1 25%;
  align-items: flex-start;
}

.cds--modal-close-button {
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;
}

.cds--modal-close {
  padding: 0.75rem;
  border: 2px solid transparent;
  background-color: transparent;
  block-size: 3rem;
  cursor: pointer;
  inline-size: 3rem;
  transition: background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.cds--modal-close:hover {
  background-color: var(--cds-layer-hover);
}
.cds--modal-close:focus {
  border-color: var(--cds-focus, #0f62fe);
  outline: none;
}

.cds--modal-close::-moz-focus-inner {
  border: 0;
}

.cds--modal-close__icon {
  block-size: 1.25rem;
  fill: var(--cds-icon-primary, #161616);
  inline-size: 1.25rem;
}

.cds--body--with-modal-open {
  overflow: hidden;
}

.cds--body--with-modal-open .cds--modal .cds--tooltip,
.cds--body--with-modal-open .cds--modal .cds--overflow-menu-options,
.cds--body--with-modal-open .cds--overflow-menu-options {
  z-index: 9000;
}

.cds--modal-container--full-width .cds--modal-content {
  padding: 0;
  margin: 0;
}

.cds--modal--slug.cds--modal,
.cds--modal--decorator:has(.cds--ai-label).cds--modal {
  background-color: var(--cds-ai-overlay, rgba(0, 17, 65, 0.5));
}

.cds--modal--slug .cds--modal-container,
.cds--modal--decorator:has(.cds--ai-label) .cds--modal-container {
  background: linear-gradient(to top, var(--cds-layer, var(--cds-ai-popover-background, #ffffff)) 0%, var(--cds-ai-aura-start, rgba(69, 137, 255, 0.1)) 0%, 15%, var(--cds-ai-aura-end, rgba(255, 255, 255, 0)) 50%) padding-box, linear-gradient(to top, var(--cds-layer, var(--cds-ai-popover-background, #ffffff)), var(--cds-layer, var(--cds-ai-popover-background, #ffffff))) padding-box, linear-gradient(to bottom, var(--cds-ai-border-start, rgba(166, 200, 255, 0.64)), var(--cds-ai-border-end, #78a9ff)) border-box, linear-gradient(to top, var(--cds-layer, var(--cds-ai-popover-background, #ffffff)), var(--cds-layer, var(--cds-ai-popover-background, #ffffff))) border-box;
  border: 1px solid transparent;
  background-color: var(--cds-layer);
  box-shadow: inset 0 -80px 70px -65px var(--cds-ai-inner-shadow, rgba(69, 137, 255, 0.1)), 0 24px 40px -24px var(--cds-ai-drop-shadow, rgba(15, 98, 254, 0.1));
}

.cds--modal--slug .cds--modal-container:has(.cds--modal-footer),
.cds--modal--decorator:has(.cds--ai-label) .cds--modal-container:has(.cds--modal-footer) {
  background: linear-gradient(to top, var(--cds-layer, var(--cds-ai-popover-background, #ffffff)) calc(0% + 64px), var(--cds-ai-aura-start, rgba(69, 137, 255, 0.1)) calc(0% + 64px), 15%, var(--cds-ai-aura-end, rgba(255, 255, 255, 0)) 50%) padding-box, linear-gradient(to top, var(--cds-layer, var(--cds-ai-popover-background, #ffffff)), var(--cds-layer, var(--cds-ai-popover-background, #ffffff))) padding-box, linear-gradient(to bottom, var(--cds-ai-border-start, rgba(166, 200, 255, 0.64)), var(--cds-ai-border-end, #78a9ff)) border-box, linear-gradient(to top, var(--cds-layer, var(--cds-ai-popover-background, #ffffff)), var(--cds-layer, var(--cds-ai-popover-background, #ffffff))) border-box;
  box-shadow: inset 0 -80px 0 -16px var(--cds-layer), inset 0 -160px 70px -65px var(--cds-ai-inner-shadow, rgba(69, 137, 255, 0.1)), 0 24px 40px -24px var(--cds-ai-drop-shadow, rgba(15, 98, 254, 0.1));
}

.cds--modal--slug .cds--modal-content.cds--modal-scroll-content,
.cds--modal--decorator .cds--modal-content.cds--modal-scroll-content {
  mask-image: linear-gradient(to bottom, var(--cds-layer) calc(100% - 80px), transparent calc(100% - 48px), transparent 100%), linear-gradient(to left, var(--cds-layer) 0, 16px, transparent 16px), linear-gradient(to right, var(--cds-layer) 0, 2px, transparent 2px), linear-gradient(to top, var(--cds-layer) 0, 2px, transparent 2px);
}

.cds--modal-header > .cds--modal--inner__decorator:has(+ .cds--modal-close-button) > *,
.cds--modal-header > .cds--modal-close-button ~ .cds--modal--inner__decorator > *,
.cds--modal--decorator .cds--modal-container-body > .cds--modal--inner__decorator > *,
.cds--modal-header > .cds--ai-label:has(+ .cds--modal-close-button),
.cds--modal-header > .cds--modal-close-button ~ .cds--ai-label,
.cds--modal--slug .cds--modal-container-body > .cds--ai-label,
.cds--modal-header > .cds--slug:has(+ .cds--modal-close-button),
.cds--modal-header > .cds--modal-close-button ~ .cds--slug,
.cds--modal--slug .cds--modal-container-body > .cds--slug {
  position: absolute;
  inset-block-start: 0.625rem;
  inset-inline-end: 3rem;
}

.cds--modal-header > .cds--modal--inner__decorator:not(:has(.cds--ai-label)) > * {
  inset-block-start: 1rem;
}

.cds--modal-header > .cds--modal--inner__decorator:has(.cds--ai-label--revert) > * {
  inset-block-start: 1.475rem;
}

.cds--modal--decorator .cds--modal-content--overflow-indicator::before,
.cds--modal--decorator .cds--modal-content--overflow-indicator {
  display: none;
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--modal-close__icon {
    fill: ButtonText;
  }
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--modal-close:focus {
    color: Highlight;
    outline: 1px solid Highlight;
  }
}

.cds--aspect-ratio {
  position: relative;
}

.cds--aspect-ratio::before {
  block-size: 0;
  content: "";
  float: left;
  inline-size: 1px;
  margin-inline-start: -1px;
}

.cds--aspect-ratio::after {
  display: table;
  clear: both;
  content: "";
}

.cds--aspect-ratio--16x9::before {
  padding-block-start: 56.25%;
}

.cds--aspect-ratio--9x16::before {
  padding-block-start: 177.7777777778%;
}

.cds--aspect-ratio--2x1::before {
  padding-block-start: 50%;
}

.cds--aspect-ratio--1x2::before {
  padding-block-start: 200%;
}

.cds--aspect-ratio--4x3::before {
  padding-block-start: 75%;
}

.cds--aspect-ratio--3x4::before {
  padding-block-start: 133.3333333333%;
}

.cds--aspect-ratio--3x2::before {
  padding-block-start: 66.6666666667%;
}

.cds--aspect-ratio--2x3::before {
  padding-block-start: 150%;
}

.cds--aspect-ratio--1x1::before {
  padding-block-start: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
  box-shadow: 0 0 0 1000px var(--cds-field) inset;
  -webkit-text-fill-color: var(--cds-text-primary, #161616);
}

.cds--fieldset {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
}
.cds--fieldset *,
.cds--fieldset *::before,
.cds--fieldset *::after {
  box-sizing: inherit;
}

.cds--form-item {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: flex-start;
}

.cds--label {
  font-size: var(--cds-label-01-font-size, 0.75rem);
  font-weight: var(--cds-label-01-font-weight, 400);
  line-height: var(--cds-label-01-line-height, 1.33333);
  letter-spacing: var(--cds-label-01-letter-spacing, 0.32px);
  display: inline-block;
  color: var(--cds-text-secondary, #525252);
  font-weight: 400;
  line-height: 1rem;
  margin-block-end: 0.5rem;
  vertical-align: baseline;
}
.cds--label html {
  font-size: 100%;
}
.cds--label body {
  font-weight: 400;
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
.cds--label code {
  font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
}
.cds--label strong {
  font-weight: 600;
}

.cds--label .cds--toggletip-label {
  font-size: var(--cds-label-01-font-size, 0.75rem);
  font-weight: var(--cds-label-01-font-weight, 400);
  line-height: var(--cds-label-01-line-height, 1.33333);
  letter-spacing: var(--cds-label-01-letter-spacing, 0.32px);
}

.cds--label--no-margin {
  margin-block-end: 0;
}

.cds--label + .cds--tooltip {
  position: relative;
  inset-block-start: 0.2rem;
  inset-inline-start: 0.5rem;
}

.cds--label + .cds--tooltip .cds--tooltip__trigger {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  appearance: none;
  background: none;
  cursor: pointer;
  text-align: start;
  inline-size: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--cds-label-01-font-size, 0.75rem);
  font-weight: var(--cds-label-01-font-weight, 400);
  line-height: var(--cds-label-01-line-height, 1.33333);
  letter-spacing: var(--cds-label-01-letter-spacing, 0.32px);
}
.cds--label + .cds--tooltip .cds--tooltip__trigger *,
.cds--label + .cds--tooltip .cds--tooltip__trigger *::before,
.cds--label + .cds--tooltip .cds--tooltip__trigger *::after {
  box-sizing: inherit;
}
.cds--label + .cds--tooltip .cds--tooltip__trigger::-moz-focus-inner {
  border: 0;
}

.cds--label + .cds--tooltip .cds--tooltip__trigger:focus {
  outline: 1px solid var(--cds-focus, #0f62fe);
}

.cds--label + .cds--tooltip .cds--tooltip__trigger svg {
  fill: var(--cds-icon-secondary, #525252);
}

.cds--label + .cds--tooltip .cds--tooltip__trigger svg :hover {
  fill: var(--cds-icon-primary, #161616);
}

.cds--label + .cds--toggletip {
  inset-block-start: 0.2rem;
  inset-inline-start: 0.5rem;
}

.cds--label.cds--skeleton {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-background, #e8e8e8);
  box-shadow: none;
  pointer-events: none;
  block-size: 0.875rem;
  inline-size: 4.6875rem;
}
.cds--label.cds--skeleton:hover, .cds--label.cds--skeleton:focus, .cds--label.cds--skeleton:active {
  border: none;
  cursor: default;
  outline: none;
}
.cds--label.cds--skeleton::before {
  position: absolute;
  animation: 3000ms ease-in-out cds--skeleton infinite;
  background: var(--cds-skeleton-element, #c6c6c6);
  block-size: 100%;
  content: "";
  inline-size: 100%;
  inset-inline-start: 0;
  will-change: transform-origin, transform, opacity;
}
@media (prefers-reduced-motion: reduce) {
  .cds--label.cds--skeleton::before {
    animation: none;
  }
}

input[type=number] {
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
}

input[data-invalid]:not(:focus),
.cds--number[data-invalid] input[type=number]:not(:focus),
.cds--text-input__field-wrapper[data-invalid] > .cds--text-input--invalid:not(:focus),
.cds--text-area__wrapper[data-invalid] > .cds--text-area--invalid:not(:focus),
.cds--select-input__wrapper[data-invalid] .cds--select-input:not(:focus),
.cds--list-box[data-invalid]:not(.cds--multi-select--invalid--focused,
.cds--combo-box--invalid--focused),
.cds--combo-box[data-invalid]:not(.cds--multi-select--selected) .cds--text-input:not(:focus) {
  outline: 2px solid var(--cds-support-error, #da1e28);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  input[data-invalid]:not(:focus),
  .cds--number[data-invalid] input[type=number]:not(:focus),
  .cds--text-input__field-wrapper[data-invalid] > .cds--text-input--invalid:not(:focus),
  .cds--text-area__wrapper[data-invalid] > .cds--text-area--invalid:not(:focus),
  .cds--select-input__wrapper[data-invalid] .cds--select-input:not(:focus),
  .cds--list-box[data-invalid]:not(.cds--multi-select--invalid--focused,
  .cds--combo-box--invalid--focused),
  .cds--combo-box[data-invalid]:not(.cds--multi-select--selected) .cds--text-input:not(:focus) {
    outline-style: dotted;
  }
}

input[data-invalid] ~ .cds--form-requirement,
.cds--number[data-invalid] .cds--number__input-wrapper ~ .cds--form-requirement,
.cds--number__input-wrapper--warning ~ .cds--form-requirement,
.cds--date-picker-input__wrapper ~ .cds--form-requirement,
.cds--date-picker-input__wrapper--warn ~ .cds--form-requirement,
.cds--date-picker-input__wrapper--invalid ~ .cds--form-requirement,
.cds--time-picker--invalid ~ .cds--form-requirement,
.cds--time-picker--warning ~ .cds--form-requirement,
.cds--text-input__field-wrapper[data-invalid] ~ .cds--form-requirement,
.cds--text-input__field-wrapper--warning ~ .cds--form-requirement,
.cds--text-input__field-wrapper--warning > .cds--text-input ~ .cds--form-requirement,
.cds--text-area__wrapper[data-invalid] ~ .cds--form-requirement,
.cds--text-area__wrapper--warn ~ .cds--form-requirement,
.cds--select-input__wrapper[data-invalid] ~ .cds--form-requirement,
.cds--select--warning .cds--select-input__wrapper ~ .cds--form-requirement,
.cds--time-picker[data-invalid] ~ .cds--form-requirement,
.cds--list-box[data-invalid] ~ .cds--form-requirement,
.cds--list-box--warning ~ .cds--form-requirement {
  display: block;
  overflow: visible;
  font-weight: 400;
  max-block-size: 12.5rem;
}

input[data-invalid] ~ .cds--form-requirement,
.cds--number[data-invalid] .cds--number__input-wrapper ~ .cds--form-requirement,
.cds--date-picker-input__wrapper ~ .cds--form-requirement,
.cds--date-picker-input__wrapper--invalid ~ .cds--form-requirement,
.cds--time-picker--invalid ~ .cds--form-requirement,
.cds--text-input__field-wrapper[data-invalid] ~ .cds--form-requirement,
.cds--text-area__wrapper[data-invalid] ~ .cds--form-requirement,
.cds--select-input__wrapper[data-invalid] ~ .cds--form-requirement,
.cds--time-picker[data-invalid] ~ .cds--form-requirement,
.cds--list-box[data-invalid] ~ .cds--form-requirement {
  color: var(--cds-text-error, #da1e28);
}

.cds--form--fluid .cds--text-input__field-wrapper[data-invalid],
.cds--form--fluid .cds--text-input__field-wrapper--warning {
  display: block;
}

.cds--form--fluid input[data-invalid] {
  outline: none;
}

.cds--form--fluid .cds--form-requirement {
  padding: 0.5rem 2.5rem 0.5rem 1rem;
  margin: 0;
}

input:not(output, [data-invalid]):-moz-ui-invalid {
  box-shadow: none;
}

.cds--form-requirement {
  font-size: var(--cds-label-01-font-size, 0.75rem);
  font-weight: var(--cds-label-01-font-weight, 400);
  line-height: var(--cds-label-01-line-height, 1.33333);
  letter-spacing: var(--cds-label-01-letter-spacing, 0.32px);
  display: none;
  overflow: hidden;
  margin: 0.25rem 0 0;
  max-block-size: 0;
}
.cds--form-requirement html {
  font-size: 100%;
}
.cds--form-requirement body {
  font-weight: 400;
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
.cds--form-requirement code {
  font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
}
.cds--form-requirement strong {
  font-weight: 600;
}

.cds--select--inline .cds--form__helper-text {
  margin-block-start: 0;
}

.cds--form__helper-text {
  font-size: var(--cds-helper-text-01-font-size, 0.75rem);
  line-height: var(--cds-helper-text-01-line-height, 1.33333);
  letter-spacing: var(--cds-helper-text-01-letter-spacing, 0.32px);
  z-index: 0;
  color: var(--cds-text-secondary, #525252);
  inline-size: 100%;
  margin-block-start: 0.25rem;
  opacity: 1;
}

.cds--label--disabled,
.cds--form__helper-text--disabled {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
}

fieldset[disabled] .cds--label,
fieldset[disabled] .cds--form__helper-text {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
}

.cds--text-input {
  --cds-layout-size-height-local: clamp(max(var(--cds-layout-size-height-min), var(--cds-layout-size-height-sm)), var(--cds-layout-size-height, var(--cds-layout-size-height-md)), min(var(--cds-layout-size-height-max), var(--cds-layout-size-height-lg)));
  --cds-layout-density-padding-inline-local: clamp(var(--cds-layout-density-padding-inline-min), var(--cds-layout-density-padding-inline, var(--cds-layout-density-padding-inline-normal)), var(--cds-layout-density-padding-inline-max));
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
  outline: 2px solid transparent;
  outline-offset: -2px;
  padding: 0 var(--cds-layout-density-padding-inline-local);
  border: none;
  background-color: var(--cds-field);
  block-size: var(--cds-layout-size-height-local);
  border-block-end: 1px solid var(--cds-border-strong);
  color: var(--cds-text-primary, #161616);
  font-family: inherit;
  inline-size: 100%;
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.cds--text-input *,
.cds--text-input *::before,
.cds--text-input *::after {
  box-sizing: inherit;
}
.cds--text-input:focus, .cds--text-input:active {
  outline: 2px solid var(--cds-focus, #0f62fe);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--text-input:focus, .cds--text-input:active {
    outline-style: dotted;
  }
}
.cds--text-input-wrapper svg[hidden] {
  display: none;
}

.cds--password-input {
  padding-inline-end: 2.5rem;
}

.cds--text-input--sm.cds--password-input {
  padding-inline-end: 2rem;
}

.cds--text-input--lg.cds--password-input {
  padding-inline-end: 3rem;
}

.cds--text-input::placeholder {
  color: var(--cds-text-placeholder, rgba(22, 22, 22, 0.4));
  opacity: 1;
}

.cds--text-input--light {
  background-color: var(--cds-field-02, #ffffff);
}

.cds--text-input__field-wrapper {
  position: relative;
  display: flex;
  inline-size: 100%;
}

.cds--text-input__invalid-icon {
  position: absolute;
  fill: var(--cds-support-error, #da1e28);
  inset-block-start: 50%;
  inset-inline-end: 1rem;
  transform: translateY(-50%);
}

.cds--text-input__invalid-icon--warning {
  fill: var(--cds-support-warning, #f1c21b);
}

.cds--text-input__invalid-icon--warning path:first-of-type {
  fill: #000000;
  opacity: 1;
}

.cds--text-input--password__visibility {
  position: relative;
  display: inline-flex;
  overflow: visible;
  align-items: center;
  cursor: pointer;
}
.cds--text-input--password__visibility:focus {
  outline: 1px solid var(--cds-focus, #0f62fe);
}
@media screen and (prefers-contrast) {
  .cds--text-input--password__visibility:focus {
    outline-style: dotted;
  }
}
.cds--text-input--password__visibility:focus {
  outline: 1px solid transparent;
}
.cds--text-input--password__visibility:focus svg {
  outline: 1px solid var(--cds-focus, #0f62fe);
}
@media screen and (prefers-contrast) {
  .cds--text-input--password__visibility:focus svg {
    outline-style: dotted;
  }
}
.cds--text-input--password__visibility::before, .cds--text-input--password__visibility::after,
.cds--text-input--password__visibility .cds--assistive-text,
.cds--text-input--password__visibility + .cds--assistive-text {
  position: absolute;
  z-index: 6000;
  display: flex;
  align-items: center;
  opacity: 0;
  pointer-events: none;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .cds--text-input--password__visibility::before, .cds--text-input--password__visibility::after,
  .cds--text-input--password__visibility .cds--assistive-text,
  .cds--text-input--password__visibility + .cds--assistive-text {
    display: inline-block;
  }
}
.cds--text-input--password__visibility::before, .cds--text-input--password__visibility::after {
  transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
@media screen and (prefers-reduced-motion: reduce) {
  .cds--text-input--password__visibility::before, .cds--text-input--password__visibility::after {
    transition: none;
  }
}
.cds--text-input--password__visibility.cds--tooltip--a11y::before, .cds--text-input--password__visibility.cds--tooltip--a11y::after {
  transition: none;
}
.cds--text-input--password__visibility::before {
  border-style: solid;
  block-size: 0;
  content: "";
  inline-size: 0;
}
.cds--text-input--password__visibility .cds--assistive-text,
.cds--text-input--password__visibility + .cds--assistive-text {
  box-sizing: content-box;
  color: inherit;
  opacity: 1;
  white-space: normal;
  word-break: break-word;
}
.cds--text-input--password__visibility::after,
.cds--text-input--password__visibility .cds--assistive-text,
.cds--text-input--password__visibility + .cds--assistive-text {
  box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
  z-index: 6000;
  padding: 0.1875rem 1rem;
  border-radius: 0.125rem;
  background-color: var(--cds-background-inverse, #393939);
  block-size: auto;
  color: var(--cds-text-inverse, #ffffff);
  font-weight: 400;
  inline-size: max-content;
  max-inline-size: 13rem;
  min-inline-size: 1.5rem;
  text-align: start;
  transform: translateX(-50%);
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .cds--text-input--password__visibility::after,
  .cds--text-input--password__visibility .cds--assistive-text,
  .cds--text-input--password__visibility + .cds--assistive-text {
    inline-size: auto;
  }
}
@supports (-ms-accelerator: true) {
  .cds--text-input--password__visibility::after,
  .cds--text-input--password__visibility .cds--assistive-text,
  .cds--text-input--password__visibility + .cds--assistive-text {
    inline-size: auto;
  }
}
@supports (-ms-ime-align: auto) {
  .cds--text-input--password__visibility::after,
  .cds--text-input--password__visibility .cds--assistive-text,
  .cds--text-input--password__visibility + .cds--assistive-text {
    inline-size: auto;
  }
}
@media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
  .cds--text-input--password__visibility::after,
  .cds--text-input--password__visibility .cds--assistive-text,
  .cds--text-input--password__visibility + .cds--assistive-text {
    border: 1px solid transparent;
  }
}
.cds--text-input--password__visibility::after {
  content: attr(aria-label);
}
.cds--text-input--password__visibility.cds--tooltip--a11y::after {
  content: none;
}
.cds--text-input--password__visibility.cds--tooltip--visible::before, .cds--text-input--password__visibility.cds--tooltip--visible::after, .cds--text-input--password__visibility:hover::before, .cds--text-input--password__visibility:hover::after, .cds--text-input--password__visibility:focus::before, .cds--text-input--password__visibility:focus::after {
  opacity: 1;
}
@keyframes cds--tooltip-fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.cds--text-input--password__visibility.cds--tooltip--visible .cds--assistive-text,
.cds--text-input--password__visibility.cds--tooltip--visible + .cds--assistive-text, .cds--text-input--password__visibility:hover .cds--assistive-text,
.cds--text-input--password__visibility:hover + .cds--assistive-text, .cds--text-input--password__visibility:focus .cds--assistive-text,
.cds--text-input--password__visibility:focus + .cds--assistive-text {
  overflow: visible;
  margin: auto;
  clip: auto;
}
.cds--text-input--password__visibility.cds--tooltip--visible .cds--assistive-text,
.cds--text-input--password__visibility.cds--tooltip--visible + .cds--assistive-text, .cds--text-input--password__visibility.cds--tooltip--visible.cds--tooltip--a11y::before, .cds--text-input--password__visibility:hover .cds--assistive-text,
.cds--text-input--password__visibility:hover + .cds--assistive-text, .cds--text-input--password__visibility:hover.cds--tooltip--a11y::before, .cds--text-input--password__visibility:focus .cds--assistive-text,
.cds--text-input--password__visibility:focus + .cds--assistive-text, .cds--text-input--password__visibility:focus.cds--tooltip--a11y::before {
  animation: cds--tooltip-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.cds--text-input--password__visibility.cds--tooltip--hidden .cds--assistive-text, .cds--text-input--password__visibility.cds--tooltip--hidden + .cds--assistive-text {
  overflow: hidden;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
}
.cds--text-input--password__visibility.cds--tooltip--hidden.cds--tooltip--a11y::before {
  animation: none;
  opacity: 0;
}
.cds--text-input--password__visibility .cds--assistive-text::after {
  position: absolute;
  display: block;
  content: "";
  block-size: 0.75rem;
  inline-size: 100%;
  inset-inline-start: 0;
  inset-block-start: -0.75rem;
}
.cds--text-input--password__visibility::before, .cds--text-input--password__visibility::after,
.cds--text-input--password__visibility .cds--assistive-text,
.cds--text-input--password__visibility + .cds--assistive-text {
  inset-block-end: 0;
  inset-inline-start: 50%;
}
.cds--text-input--password__visibility::before {
  border-width: 0 0.25rem 0.3125rem;
  border-color: transparent transparent var(--cds-background-inverse, #393939);
  inset-block-end: -0.5rem;
  transform: translate(-50%, 100%);
}
.cds--text-input--password__visibility::after,
.cds--text-input--password__visibility .cds--assistive-text,
.cds--text-input--password__visibility + .cds--assistive-text {
  inset-block-end: -0.8125rem;
  transform: translate(-50%, 100%);
}

.cds--btn.cds--text-input--password__visibility__toggle.cds--tooltip__trigger {
  outline: 2px solid transparent;
  outline-offset: -2px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 0;
  background: none;
  block-size: 100%;
  cursor: pointer;
  inline-size: 2.5rem;
  inset-inline-end: 0;
  min-block-size: auto;
  transition: outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

.cds--toggle-password-tooltip .cds--popover {
  inset-inline-start: -2.5rem;
}

.cds--toggle-password-tooltip .cds--popover-content {
  min-inline-size: 2.5rem;
}

.cds--text-input--sm + .cds--btn.cds--text-input--password__visibility__toggle.cds--tooltip__trigger {
  inline-size: 2rem;
}

.cds--text-input--lg + .cds--btn.cds--text-input--password__visibility__toggle.cds--tooltip__trigger {
  inline-size: 3rem;
}

.cds--btn.cds--text-input--password__visibility__toggle.cds--tooltip__trigger svg {
  fill: var(--cds-icon-secondary, #525252);
  transition: fill 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
@media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
  .cds--btn.cds--text-input--password__visibility__toggle.cds--tooltip__trigger svg {
    fill: ButtonText;
  }
}

.cds--btn.cds--text-input--password__visibility__toggle.cds--tooltip__trigger:focus {
  outline: 2px solid var(--cds-focus, #0f62fe);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--btn.cds--text-input--password__visibility__toggle.cds--tooltip__trigger:focus {
    outline-style: dotted;
  }
}

.cds--btn.cds--text-input--password__visibility__toggle.cds--tooltip__trigger:hover svg,
.cds--btn.cds--text-input--password__visibility__toggle.cds--tooltip__trigger:focus svg {
  fill: var(--cds-icon-primary, #161616);
}

.cds--text-input--invalid,
.cds--text-input--warning {
  padding-inline-end: 2.5rem;
}

.cds--text-input--invalid.cds--password-input {
  padding-inline-end: 4rem;
}

.cds--text-input--invalid + .cds--text-input--password__visibility__toggle {
  inset-inline-end: 1rem;
}

.cds--password-input-wrapper .cds--text-input__invalid-icon {
  inset-inline-end: 2.5rem;
}

.cds--text-input:disabled ~ .cds--text-input--password__visibility__toggle.cds--tooltip__trigger {
  cursor: not-allowed;
}

.cds--text-input:disabled ~ .cds--text-input--password__visibility__toggle.cds--tooltip__trigger svg {
  fill: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}
.cds--text-input:disabled ~ .cds--text-input--password__visibility__toggle.cds--tooltip__trigger svg:hover {
  fill: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}

.cds--text-input--password__visibility__toggle:disabled.cds--tooltip__trigger {
  cursor: default;
}
.cds--text-input--password__visibility__toggle:disabled.cds--tooltip__trigger svg {
  fill: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}

.cds--text-input--password__visibility__toggle:disabled.cds--tooltip__trigger:hover {
  cursor: default;
}
.cds--text-input--password__visibility__toggle:disabled.cds--tooltip__trigger:hover svg {
  fill: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}

.cds--text-input__counter-alert {
  position: absolute;
  overflow: hidden;
  padding: 0;
  border: 0;
  margin: -1px;
  block-size: 1px;
  clip: rect(0, 0, 0, 0);
  inline-size: 1px;
}

.cds--text-input:disabled {
  outline: 2px solid transparent;
  outline-offset: -2px;
  background-color: var(--cds-field);
  border-block-end: 1px solid transparent;
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  cursor: not-allowed;
  -webkit-text-fill-color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
}

.cds--text-input--light:disabled {
  background-color: var(--cds-field-02, #ffffff);
}

.cds--text-input:disabled::placeholder {
  color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
  opacity: 1;
}

.cds--text-input--invalid {
  outline: 2px solid var(--cds-support-error, #da1e28);
  outline-offset: -2px;
  box-shadow: none;
}
@media screen and (prefers-contrast) {
  .cds--text-input--invalid {
    outline-style: dotted;
  }
}
.cds--text-input--invalid .cds--text-input--password__visibility__toggle {
  inset-inline-end: 2.5rem;
}

.cds--skeleton.cds--text-input {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-background, #e8e8e8);
  box-shadow: none;
  pointer-events: none;
}
.cds--skeleton.cds--text-input:hover, .cds--skeleton.cds--text-input:focus, .cds--skeleton.cds--text-input:active {
  border: none;
  cursor: default;
  outline: none;
}
.cds--skeleton.cds--text-input::before {
  position: absolute;
  animation: 3000ms ease-in-out cds--skeleton infinite;
  background: var(--cds-skeleton-element, #c6c6c6);
  block-size: 100%;
  content: "";
  inline-size: 100%;
  inset-inline-start: 0;
  will-change: transform-origin, transform, opacity;
}
@media (prefers-reduced-motion: reduce) {
  .cds--skeleton.cds--text-input::before {
    animation: none;
  }
}

.cds--form--fluid .cds--text-input-wrapper {
  position: relative;
  background: var(--cds-field);
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

.cds--form--fluid .cds--label {
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  margin: 0;
  block-size: 1rem;
  inset-block-start: 0.8125rem;
  inset-inline-start: 1rem;
}

.cds--form--fluid .cds--form__helper-text {
  display: none;
}

.cds--form--fluid .cds--text-input {
  padding: 2rem 1rem 0.8125rem;
  min-block-size: 4rem;
}

.cds--text-input__divider,
.cds--form--fluid .cds--text-input__divider {
  display: none;
}

.cds--form--fluid .cds--text-input--invalid,
.cds--form--fluid .cds--text-input--warning {
  border-block-end: none;
}

.cds--form--fluid .cds--text-input--invalid + .cds--text-input__divider,
.cds--form--fluid .cds--text-input--warning + .cds--text-input__divider {
  display: block;
  border-style: solid;
  border-color: var(--cds-border-subtle);
  margin: 0 1rem;
  border-block-end: none;
}

.cds--form--fluid .cds--text-input__invalid-icon {
  inset-block-start: 5rem;
}

.cds--form--fluid .cds--text-input__field-wrapper[data-invalid] > .cds--text-input--invalid,
.cds--form--fluid .cds--text-input__field-wrapper--warning > .cds--text-input--warning {
  outline: none;
}

.cds--form--fluid .cds--text-input__field-wrapper--warning {
  border-block-end: 1px solid var(--cds-border-strong);
}

.cds--form--fluid .cds--text-input__field-wrapper[data-invalid]:not(:focus) {
  outline: 2px solid var(--cds-support-error, #da1e28);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--form--fluid .cds--text-input__field-wrapper[data-invalid]:not(:focus) {
    outline-style: dotted;
  }
}

.cds--form--fluid .cds--text-input__field-wrapper[data-invalid]:focus-within,
.cds--form--fluid .cds--text-input__field-wrapper--warning:focus-within {
  outline: 2px solid var(--cds-focus, #0f62fe);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--form--fluid .cds--text-input__field-wrapper[data-invalid]:focus-within,
  .cds--form--fluid .cds--text-input__field-wrapper--warning:focus-within {
    outline-style: dotted;
  }
}

.cds--form--fluid .cds--text-input__field-wrapper[data-invalid] > .cds--text-input--invalid:focus,
.cds--form--fluid .cds--text-input__field-wrapper--warning > .cds--text-input--warning:focus {
  outline: none;
}

.cds--text-input-wrapper.cds--text-input-wrapper--inline {
  flex-flow: row wrap;
}

.cds--text-input-wrapper .cds--label--inline {
  flex: 1;
  margin: 0.8125rem 0 0 0;
  overflow-wrap: break-word;
  word-break: break-word;
}

.cds--text-input-wrapper .cds--label--inline--sm {
  margin-block-start: 0.5625rem;
}

.cds--text-input-wrapper .cds--label--inline--lg {
  margin-block-start: 1.0625rem;
}

.cds--text-input__label-helper-wrapper {
  flex: 2;
  flex-direction: column;
  margin-inline-end: 1.5rem;
  max-inline-size: 8rem;
  overflow-wrap: break-word;
}

.cds--text-input-wrapper .cds--form__helper-text--inline {
  margin-block-start: 0.125rem;
}

.cds--text-input__field-outer-wrapper {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: flex-start;
  inline-size: 100%;
}

.cds--text-input__field-outer-wrapper--inline {
  flex: 8;
  flex-direction: column;
}

.cds--text-input-wrapper--inline .cds--form-requirement {
  display: block;
  overflow: visible;
  font-weight: 400;
  max-block-size: 12.5rem;
}

.cds--text-input-wrapper--inline--invalid .cds--form-requirement {
  color: var(--cds-text-error, #da1e28);
}

.cds--form--fluid .cds--text-input-wrapper--readonly,
.cds--text-input-wrapper--readonly .cds--text-input {
  background: transparent;
  border-block-end-color: var(--cds-border-subtle);
}

.cds--text-input__field-wrapper--decorator .cds--text-input__field-inner-wrapper--decorator > *,
.cds--text-input__field-wrapper .cds--ai-label,
.cds--text-input__field-wrapper .cds--slug {
  position: absolute;
  inset-block-start: 50%;
  inset-inline-end: 1rem;
  transform: translateY(-50%);
}

.cds--text-input__field-wrapper--decorator .cds--text-input:has(~ .cds--text-input__field-inner-wrapper--decorator .cds--ai-label):not(:has(~ .cds--text-input__field-inner-wrapper--decorator .cds--ai-label--revert)),
.cds--text-input__field-wrapper--slug .cds--text-input:has(~ .cds--ai-label):not(:has(~ .cds--ai-label--revert)),
.cds--text-input__field-wrapper--slug .cds--text-input:has(~ .cds--slug):not(:has(~ .cds--slug--revert)) {
  background-image: linear-gradient(0deg, var(--cds-ai-aura-start-sm, rgba(69, 137, 255, 0.16)) 0%, 15%, var(--cds-ai-aura-end, rgba(255, 255, 255, 0)) 50%, transparent 100%);
  border-block-end-color: var(--cds-ai-border-strong, #4589ff);
}

.cds--text-input__field-wrapper--decorator .cds--text-input:has(~ .cds--text-input__field-inner-wrapper--decorator > *),
.cds--text-input__field-wrapper--slug .cds--text-input:has(~ .cds--ai-label),
.cds--text-input__field-wrapper--slug .cds--text-input:has(~ .cds--slug) {
  padding-inline-end: 2.5rem;
}

.cds--text-input--invalid:has(~ .cds--text-input__field-inner-wrapper--decorator > *),
.cds--text-input--warning:has(~ .cds--text-input__field-inner-wrapper--decorator > *),
.cds--text-input--invalid:has(~ .cds--ai-label),
.cds--text-input--warning:has(~ .cds--ai-label),
.cds--text-input--invalid:has(~ .cds--slug),
.cds--text-input--warning:has(~ .cds--slug) {
  padding-inline-end: 4rem;
}

.cds--text-input--invalid ~ .cds--text-input__field-inner-wrapper--decorator > *,
.cds--text-input--warning ~ .cds--text-input__field-inner-wrapper--decorator > *,
.cds--text-input--invalid ~ .cds--ai-label,
.cds--text-input--warning ~ .cds--ai-label,
.cds--text-input--invalid ~ .cds--slug,
.cds--text-input--warning ~ .cds--slug {
  inset-inline-end: 2.5rem;
}

.cds--text-input__field-wrapper--decorator .cds--text-input__field-inner-wrapper--decorator:not(:has(.cds--ai-label)) > * {
  block-size: 1rem;
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  .cds--btn.cds--btn--icon-only.cds--text-input--password__visibility__toggle.cds--tooltip__trigger svg,
  .cds--btn.cds--btn--icon-only.cds--text-input--password__visibility__toggle.cds--tooltip__trigger:hover svg {
    fill: ButtonText;
  }
}

.cds--text-input__label-wrapper {
  display: flex;
  justify-content: space-between;
  inline-size: 100%;
}